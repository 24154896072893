import React, { useContext, useState, useEffect, useRef } from "react";
import {
  StyleSheet,
  View,
  Image,
  ScrollView,
  TouchableOpacity,
} from "react-native";
import { Context as PlanContext } from "@context/PlanContext";
import { Context as LocalContext } from "@context/LocalContext";
import { Text, Input, Button, Card } from "@geist-ui/react";
import colors from "@res/colors";
import fonts from "@res/fonts";
import { Ionicons } from "@expo/vector-icons";
import ReactFlow, { Background, Controls, Handle } from "react-flow-renderer";
import RemoteImage from "../../../../../components/RemoteImage";

const initialNodes = [
  {
    id: "1",
    type: "locationNode",
    data: {
      label: "Input Node",
    },
    position: { x: 80, y: 25 },
  },

  {
    id: "2",
    type: "insuranceNode",
    data: { label: "Default Node" },
    position: { x: 80, y: 125 },
  },
  {
    id: "3",
    type: "specialtyNode",
    data: { label: "Output Node" },
    position: { x: 80, y: 225 },
  },
  {
    id: "4",
    type: "outputNode",
    position: { x: 400, y: 40 },
  },
];

const initialEdges = [
  { id: "e1-4", source: "1", target: "4", targetHandle: "a", animated: true },
  { id: "e2-4", source: "2", target: "4", targetHandle: "b", animated: true },
  { id: "e3-4", source: "3", target: "4", targetHandle: "c", animated: true },
];

const customLocationNode = ({ data, isConnectable }) => {
  return (
    <View
      style={{
        backgroundColor: "white",
        borderColor: "#d2d8dc",
        borderWidth: 1,
        borderRadius: 5,
      }}
    >
      <View style={{ width: 175, height: 50 }}>
        <View
          style={{
            borderTopLeftRadius: 5,
            borderTopRightRadius: 5,
            backgroundColor: "#fefefe",
            width: "100%",
            paddingVertical: 2,
            paddingHorizontal: 8,
            borderBottomColor: "#d2d8dc",
            borderBottomWidth: 0.5,
            marginBottom: 10,
          }}
        >
          <Text
            small
            style={{ paddingVertical: 0, marginVertical: 0 }}
            font="8px"
          >
            INPUT
          </Text>
        </View>
        <View style={{ paddingHorizontal: 8 }}>
          <Text small font="11px">
            Location:{" "}
            <Text span b>
              New York, NY
            </Text>
          </Text>
        </View>
      </View>

      <Handle
        type="source"
        position="right"
        id="a"
        style={{ top: 30, background: "#555" }}
        isConnectable={isConnectable}
      />
    </View>
  );
};

const customSpecialtyNode = ({ data, isConnectable }) => {
  return (
    <View
      style={{
        backgroundColor: "white",
        borderColor: "#d2d8dc",
        borderWidth: 1,
        borderRadius: 5,
      }}
    >
      <View style={{ width: 175, height: 50 }}>
        <View
          style={{
            borderTopLeftRadius: 5,
            borderTopRightRadius: 5,
            backgroundColor: "#fefefe",
            width: "100%",
            paddingVertical: 2,
            paddingHorizontal: 8,
            borderBottomColor: "#d2d8dc",
            borderBottomWidth: 0.5,
            marginBottom: 10,
          }}
        >
          <Text
            small
            style={{ paddingVertical: 0, marginVertical: 0 }}
            font="8px"
          >
            INPUT
          </Text>
        </View>
        <View style={{ paddingHorizontal: 8 }}>
          <Text small font="11px">
            Specialty:{" "}
            <Text span b>
              Chiropractor
            </Text>
          </Text>
        </View>
      </View>

      <Handle
        type="source"
        position="right"
        id="a"
        style={{ top: 30, background: "#555" }}
        isConnectable={isConnectable}
      />
    </View>
  );
};

const customInsuranceNode = ({ data, isConnectable }) => {
  return (
    <View
      style={{
        backgroundColor: "white",
        borderColor: "#d2d8dc",
        borderWidth: 1,
        borderRadius: 5,
      }}
    >
      <View style={{ width: 175, height: 50 }}>
        <View
          style={{
            borderTopLeftRadius: 5,
            borderTopRightRadius: 5,
            backgroundColor: "#fefefe",
            width: "100%",
            paddingVertical: 2,
            paddingHorizontal: 8,
            borderBottomColor: "#d2d8dc",
            borderBottomWidth: 0.5,
            marginBottom: 10,
          }}
        >
          <Text
            small
            style={{ paddingVertical: 0, marginVertical: 0 }}
            font="8px"
          >
            INPUT
          </Text>
        </View>
        <View style={{ paddingHorizontal: 8 }}>
          <Text small font="11px">
            Carrier:{" "}
            <Text span b>
              Aetna
            </Text>
          </Text>
        </View>
      </View>

      <Handle
        type="source"
        position="right"
        id="a"
        style={{ top: 30, background: "#555" }}
        isConnectable={isConnectable}
      />
    </View>
  );
};

const customOutputNode = ({ data, isConnectable }) => {
  return (
    <View
      style={{
        backgroundColor: "white",
        borderColor: "#d2d8dc",
        borderWidth: 1,
        borderRadius: 5,
      }}
    >
      <Handle
        type="target"
        position="left"
        id="a"
        style={{ top: 30, background: "#555" }}
        onConnect={(params) => console.log("handle onConnect", params)}
        isConnectable={isConnectable}
      />
      <Handle
        type="target"
        position="left"
        id="b"
        style={{ top: 50, background: "#555" }}
        onConnect={(params) => console.log("handle onConnect", params)}
        isConnectable={isConnectable}
      />
      <Handle
        type="target"
        position="left"
        id="c"
        style={{ top: 70, background: "#555" }}
        onConnect={(params) => console.log("handle onConnect", params)}
        isConnectable={isConnectable}
      />
      <View style={{ width: 200, height: 175 }}>
        <View
          style={{
            borderTopLeftRadius: 5,
            borderTopRightRadius: 5,
            backgroundColor: "#fefefe",
            width: "100%",
            paddingVertical: 2,
            paddingHorizontal: 8,
            borderBottomColor: "#d2d8dc",
            borderBottomWidth: 0.5,
            marginBottom: 10,
          }}
        >
          <Text
            small
            style={{ paddingVertical: 0, marginVertical: 0 }}
            font="8px"
          >
            OUTPUT
          </Text>
        </View>

        <ScrollView
          style={{ height: 300 }}
          showsVerticalScrollIndicator={false}
        >
          {["Noah Smith", "Sarah Jones", "Alex Braun", "Derek Alvin"].map(
            (elem) => (
              <View
                key={elem}
                style={{
                  width: "80%",
                  alignSelf: "center",
                  padding: 5,
                  marginVertical: 5,
                  borderWidth: 0.5,
                  borderColor: "#d2d8dc",
                  flexDirection: "row",
                  alignItems: "center",
                  borderRadius: 5,
                }}
              >
                <Ionicons
                  name="person-circle-sharp"
                  size={16}
                  color="black"
                  style={{ marginRight: 10 }}
                />
                <Text
                  small
                  style={{
                    marginVertical: 0,
                    paddingVertical: 0,
                  }}
                  font="9px"
                >
                  Provider:{" "}
                  <Text span b>
                    {elem}
                  </Text>
                </Text>
              </View>
            )
          )}
        </ScrollView>
      </View>
    </View>
  );
};

const nodeTypes = {
  locationNode: customLocationNode,
  specialtyNode: customSpecialtyNode,
  insuranceNode: customInsuranceNode,
  outputNode: customOutputNode,
};

const GoalFlow = () => {
  const {
    selectTab,
    state: { plan },
  } = useContext(PlanContext);
  const [nodes, setNodes] = useState(initialNodes);
  const [edges, setEdges] = useState(initialEdges);

  return (
    <View
      style={{
        width: "100%",
        height: "100%",
        borderRadius: 10,
      }}
    >
      <View style={{ position: "absolute", top: 15, left: 25, zIndex: 40 }}>
        <RemoteImage desiredWidth={60} uri={plan.company.logo} />
      </View>
      <View
        style={{
          backgroundColor: "white",
          width: 60,
          height: 20,
          position: "absolute",
          bottom: 0,
          right: 0,
          zIndex: 5000,
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#535275",
          borderBottomRightRadius: 10,
        }}
      >
        <Text style={{ color: "white" }} font="8px">
          {plan.company.name} API
        </Text>
      </View>
      <ReactFlow
        nodes={nodes}
        nodeTypes={nodeTypes}
        edges={edges}
        fitView
        style={{ borderRadius: 10 }}
      >
        <Background />
        <Controls />
      </ReactFlow>
    </View>
  );
};

const styles = StyleSheet.create({
  databaseView: {
    padding: 30,
    alignSelf: "center",
    borderRadius: 10,
    backgroundColor: "white",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.1,
    shadowRadius: 15,

    elevation: 9,
  },
});

export default GoalFlow;
