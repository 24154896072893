import React, { useContext, useState, useEffect, useRef } from "react";
import {
  StyleSheet,
  View,
  Image,
  ScrollView,
  TouchableOpacity,
} from "react-native";
import { Context as PlanContext } from "@context/PlanContext";
import { Context as LocalContext } from "@context/LocalContext";
import { Text, Input, Button, Card } from "@geist-ui/react";
import { ConversationalForm } from "conversational-form";
import colors from "@res/colors";
import fonts from "@res/fonts";
import { Ionicons } from "@expo/vector-icons";
import { motion } from "framer-motion";
import { toast } from "react-toastify";

const ChatForm = ({ order, stepContent }) => {
  const {
    selectTab,
    state: { plan },
  } = useContext(PlanContext);
  const {
    modDatabase,
    state: { localDatabase },
  } = useContext(LocalContext);
  const formRef = useRef(null);
  const cf = useRef(null);
  //const [cf, setCf] = useState(null);

  const submitCallback = () => {
    const formDataSerialized = cf.current.getFormData(true);
    console.log("Formdata, obj:", formDataSerialized);
    modDatabase({ key: stepContent.dbTitle, value: formDataSerialized });
    cf.current.addRobotChatResponse(
      "Nice! Looks like your policy has been created."
    );
  };

  useEffect(() => {
    cf.current = ConversationalForm.startTheConversation({
      options: {
        submitCallback: () => onFinish(),
        preventAutoFocus: true,
        // loadExternalStyleSheet: false
      },
      tags: stepContent.content.formFields,
    });
  }, []);

  useEffect(() => {
    if (cf.current != null && formRef.current != null) {
      formRef.current.appendChild(cf.current.el);
    }
  }, [cf, formRef]);

  const onFinish = () => {
    submitCallback();
  };

  return (
    <View
      style={{
        width: "100",
        alignContent: "center",
        justifyContent: "center",
      }}
    >
      <View
        style={{
          alignSelf: "center",
          width: "80%",
          height: 500,
          borderWidth: 2,
          borderColor: "#f2f2f2",
        }}
      >
        <div style={{ borderRadius: 5 }} ref={formRef} />
      </View>
      {localDatabase[stepContent.dbTitle] != null ? (
        <View style={{ width: "80%", alignSelf: "center", marginTop: 25 }}>
          <TouchableOpacity
            style={{
              backgroundColor: plan.company.darkColor,
              width: "100%",
              paddingVertical: 8,
              alignItems: "center",
              justifyContent: "space-between",
              borderRadius: 5,
              flexDirection: "row",
              paddingHorizontal: 15,
            }}
            onPress={() => selectTab({ index: order + 1, type: "manual" })}
          >
            <Ionicons
              name="ios-checkmark-circle"
              size={24}
              color={plan.company.lightColor}
            />
            <Text style={{ color: plan.company.lightColor }} small b>
              {eval(stepContent.content.successLabel)}
            </Text>
            <View />
          </TouchableOpacity>
        </View>
      ) : null}
    </View>
  );
};

const styles = StyleSheet.create({
  databaseView: {
    padding: 30,
    alignSelf: "center",
    borderRadius: 10,
    backgroundColor: "white",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.1,
    shadowRadius: 15,

    elevation: 9,
  },
});

export default ChatForm;
