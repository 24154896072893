import React, { useContext, useState, useEffect, useRef } from "react";
import {
  StyleSheet,
  View,
  ScrollView,
  useWindowDimensions,
  ActivityIndicator,
  TouchableOpacity,
  Image,
} from "react-native";
import { Context as PlanContext } from "@context/PlanContext";
import { useMediaQuery } from "react-responsive";
import { Text, Button, Tag, Card, Input, Table } from "@geist-ui/react";
import colors from "@res/colors";
import fonts from "@res/fonts";
import { Ionicons } from "@expo/vector-icons";

const Banner = ({ order }) => {
  const {
    state: { plan, tabs },
  } = useContext(PlanContext);

  const stepContent = tabs[order];

  return (
    <View style={styles.container}>
      <View
        style={{
          width: "100%",
          alignSelf: "center",
          paddingHorizontal: 50,
          paddingVertical: 20,
          marginTop: 150,
          borderRadius: 10,
          background:
            "linear-gradient(" +
            120 +
            "deg, " +
            plan.company.firstGradientColor +
            ", " +
            plan.company.secondGradientColor +
            ")",
          flexDirection: "row",
          justifyContent: "space-around",
        }}
      >
        <View style={{ width: "60%", paddingVertical: 30 }}>
          <Text
            style={{
              color: "white",
              paddingBottom: 0,
              marginBottom: 0,
              fontFamily:
                plan.company.headingFont != null
                  ? plan.company.headingFont
                  : null,
            }}
            h1
          >
            {stepContent.title}
          </Text>
          <Text style={{ color: "white", opacity: 0.65 }} p>
            {stepContent.description}
          </Text>
        </View>
        <View>
          <Image
            source={require("../../../assets/images/gift-dynamic-clay.png")}
            style={{
              height: 200,
              width: 200,
              alignSelf: "center",
            }}
          />
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: "100%",
  },
});

export default Banner;
