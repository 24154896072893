import React, { useContext, useState, useEffect, useRef } from "react";
import {
  StyleSheet,
  View,
  Image,
  ScrollView,
  useWindowDimensions,
  ActivityIndicator,
  TouchableOpacity,
} from "react-native";
import { Context as PlanContext } from "@context/PlanContext";
import { Context as LocalContext } from "@context/LocalContext";
import { useMediaQuery } from "react-responsive";
import {
  Text,
  Button,
  Tag,
  Badge,
  Card,
  Input,
  Dot,
  Tooltip,
  Description,
} from "@geist-ui/react";
import { Collapse } from "react-collapse";
import colors from "@res/colors";
import fonts from "@res/fonts";
import { Ionicons } from "@expo/vector-icons";
import { motion } from "framer-motion";
import pickTextColor from "../../functions/pickTextColor";

const calculateScore = (confidence) => {
  switch (confidence) {
    case 1:
      return {
        percent: "20%",
        type: "error",
      };
    case 2:
      return {
        percent: "40%",
        type: "warning",
      };
    case 3:
      return {
        percent: "60%",
        type: "secondary",
      };
    case 4:
      return {
        percent: "80%",
        type: "success",
      };
    case 5:
      return {
        percent: "99%",
        type: "success",
      };
  }
};

const DataList = ({ content }) => {
  const {
    state: { plan, gradients },
  } = useContext(PlanContext);
  const {
    state: { localDatabase },
  } = useContext(LocalContext);
  const [toggle, setToggle] = useState(-1);
  const actionColor = pickTextColor(
    plan.company.darkColor,
    plan.company.darkColor,
    plan.company.lightColor
  );

  const itemList =
    _.get(localDatabase, content.data) != null
      ? _.get(localDatabase, content.data)
      : content.preFillData;

  if (!itemList) return <ActivityIndicator />;

  const lineItem = (elem, index) => (
    <motion.div layout>
      <TouchableOpacity
        style={{
          backgroundColor:
            elem.flagged == null || elem.flagged == false
              ? "#f7f7f7"
              : "#ffc9c9",
          padding: 15,
          marginBottom: 12,
        }}
        onPress={() => setToggle(index)}
      >
        <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
          <View>
            <Text small>
              {elem.first_name} {elem.last_name}
            </Text>
          </View>
          <View
            style={{
              flexDirection: "row",
              alignItems: "flex-start",
            }}
          >
            <Tag>{elem.specialties[0].display}</Tag>
          </View>
        </View>
        <View style={{ marginTop: 25 }}>
          <Description
            title="Top Result"
            content={
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <View>
                  <Text small>
                    {elem.locations[0] != null
                      ? elem.locations[0].address
                      : "N/A"}
                  </Text>
                  <Text small>
                    {elem.locations[0] != null &&
                    elem.locations[0].phone_numbers[0] != null
                      ? elem.locations[0].phone_numbers[0].phone
                      : "N/A"}
                  </Text>
                </View>
                <View>
                  {/* <Text style={{ color: "grey", marginBottom: 5 }} small>
                    Confidence
                  </Text> */}
                  <View style={{ alignSelf: "flex-end" }}>
                    <Badge
                      type={calculateScore(elem.locations[0].confidence).type}
                    >
                      {calculateScore(elem.locations[0].confidence).percent}
                    </Badge>
                  </View>
                </View>
              </View>
            }
          />
        </View>
        <Collapse isOpened={toggle == index}>
          <View style={{ marginVertical: 15 }} />
          <Description
            title="All Results"
            content={
              <>
                {elem.locations.slice(1, 6).map((subElem) => {
                  const score = calculateScore(subElem.confidence);

                  return (
                    <View
                      key={subElem.address}
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        marginTop: 15,
                      }}
                    >
                      <View>
                        <Text small>{subElem.address}</Text>
                        <Text small>
                          {subElem.phone_numbers[0] != null
                            ? subElem.phone_numbers[0].phone
                            : "N/A"}
                        </Text>
                      </View>
                      <View>
                        <View style={{ alignSelf: "flex-end" }}>
                          <Badge type={score.type}>{score.percent}</Badge>
                        </View>
                      </View>
                    </View>
                  );
                })}
              </>
            }
          />
        </Collapse>
      </TouchableOpacity>
    </motion.div>
  );

  return (
    <View
      style={[
        styles.databaseView,
        {
          borderTopColor: actionColor,
          borderTopWidth: 3,
          width: "100%",
        },
      ]}
    >
      <View style={{ width: "100%" }}>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
            marginBottom: 20,
          }}
        >
          <View>
            <Text
              style={{
                color: "grey",
                marginTop: 0,
                paddingTop: 0,
                marginBottom: 0,
              }}
            >
              {eval(content.subHeader)}
            </Text>
            <Text
              style={{
                color: actionColor,
                fontFamily:
                  plan.company.headingFont != null
                    ? plan.company.headingFont
                    : null,
              }}
              h4
            >
              {eval(content.header)}
            </Text>
          </View>

          <Tag>{eval(content.label)}</Tag>
        </View>
        <View
          style={{
            borderColor: "lightgrey",
            borderWidth: 0.5,
            borderRadius: 5,
            marginBottom: 40,
          }}
        >
          <ScrollView
            contentContainerStyle={{
              flexDirection: "row",
              justifyContent: "space-between",
              flexWrap: "wrap",
              maxHeight: 400,
              paddingVertical: 15,
              paddingHorizontal: 15,
              alignItems: "flex-start",
            }}
          >
            {itemList.map((elem, index) => {
              return (
                <motion.div
                  key={elem.npi}
                  style={{ width: "100%" }}
                  whileHover={{ scale: 1.05 }}
                >
                  {elem.flagged != null && elem.flagged == true ? (
                    <>
                      <Tooltip
                        text={
                          "Flagged after policy review - ineligible purchase"
                        }
                        type="dark"
                        style={{ width: "100%" }}
                      >
                        {lineItem(elem, index)}
                      </Tooltip>
                    </>
                  ) : (
                    <>{lineItem(elem, index)}</>
                  )}
                </motion.div>
              );
            })}
          </ScrollView>
        </View>
        <TouchableOpacity
          style={{
            backgroundColor: plan.company.darkColor,
            width: "100%",
            paddingVertical: 8,
            alignItems: "center",
            justifyContent: "space-between",
            borderRadius: 5,
            flexDirection: "row",
            paddingHorizontal: 15,
          }}
        >
          <Ionicons
            name="ios-checkmark-circle"
            size={24}
            color={plan.company.lightColor}
          />
          <Text style={{ color: plan.company.lightColor }} small b>
            {eval(content.successLabel)}
          </Text>
          <View />
        </TouchableOpacity>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  databaseView: {
    padding: 30,
    alignSelf: "center",
    borderRadius: 10,
    backgroundColor: "white",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.1,
    shadowRadius: 15,
    elevation: 9,
  },
});

export default DataList;
