import React, { useContext, useState, useEffect, useRef } from "react";
import {
  StyleSheet,
  View,
  useWindowDimensions,
  ActivityIndicator,
  Image,
} from "react-native";
import { Context as PlanContext } from "@context/PlanContext";
import { Context as LocalContext } from "@context/LocalContext";
import {
  Text,
  Input,
  Button,
  Select,
  Card,
  useBodyScroll,
} from "@geist-ui/react";
import colors from "@res/colors";
import fonts from "@res/fonts";
import StepBot from "../Endpoint/StepBot";
import Success from "@components/SuccessComponent/SuccessComponent";
import { motion } from "framer-motion";
import { Ionicons } from "@expo/vector-icons";
import { InView } from "react-intersection-observer";
import { toast } from "react-toastify";
import _ from "lodash";

const VisualTrigger = ({
  order,
  stepContent,
  scrollRef,
  scrollDown,
  isScrolling,
}) => {
  const {
    state: { gradients, tabs, plan },
  } = useContext(PlanContext);
  const {
    modDatabase,
    state: { localDatabase },
  } = useContext(LocalContext);
  const botRef = useRef(null);
  const successRef = useRef(null);
  const [didExecute, setDidExecute] = useState(false);
  const [triggerInView, setTriggerInView] = useState(false);
  const [executeTrigger, setExecuteTrigger] = useState(false);
  //const [scrollDown, setScrollDown] = useState(false);
  const [locked, setLocked] = useBodyScroll(scrollRef);
  const [displayTrigger, setDisplayTrigger] = useState(false);

  const topRef = useRef(null);
  const windowDims = useWindowDimensions();

  const onExecute = async () => {
    await modDatabase({
      key: tabs[order].dbTitle,
      value: _.cloneDeep(stepContent.content.payload),
    });
    botRef.current.scrollIntoView({
      behavior: "smooth",
    });
    setDidExecute(true);
  };

  const lineVariants = {
    visible: { display: "block", height: "auto", opacity: 1 },
    hidden: { display: "none", height: 0, opacity: 0 },
  };

  const evalPreload = stepContent.content.preloadKeys.every(
    (elem) => localDatabase[elem] != null
  );

  if (evalPreload == false) {
    return (
      <Card style={{ marginTop: 25 }} type="warning">
        <Text small>
          <Text b>Previous step must be completed to continue</Text>
        </Text>
      </Card>
    );
  } else {
    return (
      <View ref={topRef} style={{ width: "100%", minHeight: 1400 }}>
        <View style={{ width: "80%", alignSelf: "center" }}>
          <Card>
            <View
              style={{
                alignItems: "center",
                width: "100%",
              }}
            >
              <View style={{ alignItems: "center", width: "100%" }}>
                <Image
                  source={{ uri: eval(stepContent.content.headerLogo) }}
                  style={{
                    height: 75,
                    width: 75,
                    borderRadius: 75,
                    borderWidth: 1,
                    borderColor: "lightgrey",
                  }}
                  resizeMode="contain"
                />
                <View style={{ marginVertical: 5 }} />
                <Text style={{ textAlign: "center", width: "80%" }} h3>
                  {eval(stepContent.content.header)}
                </Text>
              </View>
              <View style={{ marginVertical: 20 }} />
              <Button type="success" onClick={() => onExecute()}>
                {eval(stepContent.content.buttonLabel)}
              </Button>
            </View>
          </Card>
        </View>

        <motion.div
          animate={{ opacity: didExecute ? 1 : 0 }}
          style={{
            alignSelf: "center",
            width: "100%",
          }}
          ref={botRef}
        >
          <View style={{ alignSelf: "center", marginTop: 20 }}>
            {didExecute ? (
              <motion.div
                variants={lineVariants}
                initial="hidden"
                animate={"visible"}
                transition={{ duration: 2 }}
                style={{ alignSelf: "center" }}
              >
                <View
                  style={{
                    width: 1,
                    height: 75,
                    borderLeftWidth: 1,
                    borderLeftColor: "grey",
                    borderStyle: "dashed",
                    alignSelf: "center",
                  }}
                />
              </motion.div>
            ) : null}
          </View>

          <View style={{ width: "100%", alignItems: "center" }}>
            <View
              style={[styles.botContainer, { background: gradients[order] }]}
            >
              <StepBot
                didExecute={didExecute}
                botDetails={stepContent.content.botDetails}
                successRef={successRef}
              />
            </View>
          </View>

          {didExecute ? (
            <View style={{ alignSelf: "center", marginTop: 20 }}>
              <motion.div
                variants={lineVariants}
                initial="hidden"
                animate={"visible"}
                transition={{ duration: 1, delay: [1, 2, 3].length * 1 + 0.5 }}
                style={{ alignSelf: "center" }}
              >
                <View
                  style={{
                    width: 1,
                    height: 75,
                    borderLeftWidth: 1,
                    borderLeftColor: "grey",
                    borderStyle: "dashed",
                    alignSelf: "center",
                  }}
                />
              </motion.div>
              <View style={{ marginVertical: 20, width: "100%" }}>
                <View
                  style={{ position: "absolute", top: -50 }}
                  ref={successRef}
                />
                <motion.div
                  variants={lineVariants}
                  onAnimationComplete={() => setLocked(false)}
                  initial="hidden"
                  animate={"visible"}
                  transition={{ duration: 1, delay: [1, 2, 3].length * 1 + 1 }}
                  style={{
                    alignSelf: "center",
                    width:
                      stepContent.content.success.componentType == 3 ||
                      stepContent.content.success.componentType == 4 ||
                      stepContent.content.success.componentType == 6
                        ? "80%"
                        : null,
                  }}
                >
                  <Success data={stepContent.content.success} />
                </motion.div>
              </View>
            </View>
          ) : null}
        </motion.div>
      </View>
    );
  }
};

const styles = StyleSheet.create({
  botContainer: {
    alignSelf: "center",
    width: "80%",
    alignItems: "center",
    justifyContent: "center",
    maxWidth: 600,
    marginTop: 20,
    marginBottom: 20,
    borderRadius: 5,
    padding: 35,
  },
  botBackground: {
    position: "absolute",
    borderRadius: 5,
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    height: "100%",
    zIndex: -1,
  },
  dashedLine: {
    alignSelf: "center",
    borderLeftWidth: 1,
    borderStyle: "dashed",
    height: 100,
    width: 0,
  },
});

export default VisualTrigger;
