import React, { useContext, useState, useEffect, useRef } from "react";
import {
  StyleSheet,
  View,
  Image,
  ScrollView,
  TouchableOpacity,
} from "react-native";
import { Context as PlanContext } from "@context/PlanContext";
import { Context as LocalContext } from "@context/LocalContext";
import { Text, Input, Button, Card } from "@geist-ui/react";
import colors from "@res/colors";
import fonts from "@res/fonts";
import { Ionicons } from "@expo/vector-icons";
import { motion } from "framer-motion";
import { toast } from "react-toastify";
import "./devices.min.css";
import RemoteImage from "../../RemoteImage";
import pickTextColor from "../../../functions/pickTextColor";

const MemoImage = React.memo(function ({ source, style }) {
  return <Image source={source} style={style} resizeMode="contain" />;
});

const Connect = ({ order, stepContent }) => {
  const {
    selectTab,
    state: { plan },
  } = useContext(PlanContext);
  const {
    modDatabase,
    state: { localDatabase },
  } = useContext(LocalContext);
  const [stage, setStage] = useState(0);
  const actionColor = pickTextColor(
    plan.company.darkColor,
    plan.company.darkColor,
    plan.company.lightColor
  );

  const onSelect = (merchant) => {
    modDatabase({ key: stepContent.dbTitle, value: merchant });
  };

  const advanceStage = () => {
    setStage(stage + 1);
  };

  const phoneElem = (content) => {
    return (
      <div className="marvel-device iphone-x" style={{ "--size-divisor": 1.2 }}>
        <div className="notch">
          <div className="camera"></div>
          <div className="speaker"></div>
        </div>
        <div className="top-bar"></div>
        <div className="sleep"></div>
        <div className="bottom-bar"></div>
        <div className="volume"></div>
        <div className="overflow">
          <div className="shadow shadow--tr"></div>
          <div className="shadow shadow--tl"></div>
          <div className="shadow shadow--br"></div>
          <div className="shadow shadow--bl"></div>
        </div>
        <div className="inner-shadow"></div>
        <div className="screen">{content}</div>
      </div>
    );
  };

  const step0 = () => (
    <View
      style={{
        width: "100%",
        height: "100%",
        paddingVertical: 50,
        paddingHorizontal: 20,
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <View style={{ alignItems: "center" }}>
        <View style={{ flexDirection: "row" }}>
          <Image
            source={{ uri: plan.prospectLogo }}
            resizeMode="contain"
            style={{
              backgroundColor: "#E8E8E8",
              height: 60,
              width: 60,
              borderRadius: 60,
              borderColor: "white",
              borderWidth: 4,
            }}
          />
          <Image
            source={{ uri: "https://i.ibb.co/B3Qn8Vz/plaid-logo-black-bg.png" }}
            resizeMode="contain"
            style={{
              marginLeft: -15,
              backgroundColor: "white",
              height: 60,
              width: 60,
              borderRadius: 60,
              borderColor: "white",
              borderWidth: 4,
            }}
          />
        </View>

        <View style={{ marginVertical: 2 }} />

        <Text font="20px">
          {plan.prospectCompany} uses{" "}
          <Text span b>
            Plaid
          </Text>{" "}
          to connect your account
        </Text>

        <View style={{ marginVertical: 10 }} />
        <View style={{ flexDirection: "row" }}>
          <Ionicons
            name="checkmark-done"
            size={24}
            color="black"
            style={{ marginRight: 10 }}
          />
          <View style={{ width: "80%" }}>
            <Text b>Connect effortlessly</Text>
            <Text style={{ color: "grey", marginTop: 2 }} font="14px">
              Plaid lets you securely connect your financial accounts in seconds
            </Text>
          </View>
        </View>
        <View style={{ flexDirection: "row" }}>
          <Ionicons
            name="eye-off-outline"
            size={24}
            color="black"
            style={{ marginRight: 10 }}
          />
          <View style={{ width: "80%" }}>
            <Text b>Your data belongs to you</Text>
            <Text style={{ color: "grey", marginTop: 2 }} font="14px">
              Plaid doesn't sell personal info, and will only use it with your
              permission
            </Text>
          </View>
        </View>
      </View>
      <Button width={"100%"} onClick={() => advanceStage()} type="secondary">
        Continue
      </Button>
    </View>
  );

  const step1 = () => (
    <View
      style={{
        width: "100%",
        height: "100%",
        paddingVertical: 30,
        paddingHorizontal: 20,
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <TouchableOpacity
        style={{ alignSelf: "flex-start" }}
        onPress={() => setStage(stage - 1)}
      >
        <Ionicons name="arrow-back" size={20} color="black" />
      </TouchableOpacity>
      <View>
        <Text b font="22px">
          Select your {stepContent.content.objectName}
        </Text>

        <View style={{ marginVertical: 10 }} />
        <View
          style={{
            borderColor: "lightgrey",
            borderWidth: 0.5,
            borderRadius: 5,
            marginBottom: 40,
          }}
        >
          <ScrollView
            contentContainerStyle={{
              maxHeight: 400,
              paddingVertical: 15,
              paddingHorizontal: 15,
              alignItems: "flex-start",
              width: "100%",
            }}
          >
            {stepContent.content.data.map((elem) => {
              const isSelected =
                localDatabase[stepContent.dbTitle] != null &&
                `${localDatabase[stepContent.dbTitle].id}` == `${elem.id}`;

              return (
                <TouchableOpacity
                  key={`${elem.id}`}
                  onPress={() => onSelect(elem)}
                  style={{
                    marginBottom: 40,
                    marginHorizontal: 5,
                    alignItems: "flex-start",
                    flexDirection: "row",
                    width: "100%",
                  }}
                >
                  <motion.div whileHover={{ scale: 1.1 }} layout>
                    <MemoImage
                      source={{ uri: elem.logo }}
                      style={{
                        width: 40,
                        height: 40,
                        borderRadius: 40,
                        backgroundColor: "white",
                        marginRight: 10,
                      }}
                    />
                  </motion.div>
                  <View>
                    <Text style={{ marginTop: 0, marginBottom: 0 }}>
                      {elem.parent_name}
                    </Text>
                    <Text
                      style={{ color: "grey", marginTop: 0 }}
                      small
                      font="11px"
                    >
                      {elem.website}
                    </Text>
                  </View>
                </TouchableOpacity>
              );
            })}
          </ScrollView>
        </View>
      </View>
      {!localDatabase[stepContent.dbTitle] ||
      localDatabase[stepContent.dbTitle] == null ? (
        <Button width={"100%"} style={{ fontWeight: "bold" }} disabled>
          No {stepContent.content.objectName} Selected
        </Button>
      ) : (
        <Button
          width={"100%"}
          type="secondary"
          onClick={() => {
            advanceStage();
          }}
        >
          <Text b>
            {localDatabase[stepContent.dbTitle].parent_name} Selected
          </Text>
        </Button>
      )}
    </View>
  );

  const step2 = () => (
    <View
      style={{
        width: "100%",
        height: "100%",
        paddingVertical: 50,
        paddingHorizontal: 20,
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <View>
        <View style={{ flexDirection: "row", marginBottom: 20 }}>
          <Image
            source={{ uri: "https://i.ibb.co/B3Qn8Vz/plaid-logo-black-bg.png" }}
            resizeMode="contain"
            style={{
              backgroundColor: "#E8E8E8",
              height: 50,
              width: 50,
              borderRadius: 50,
              borderColor: "white",
              borderWidth: 4,
            }}
          />
          <Image
            source={{ uri: localDatabase[stepContent.dbTitle].logo }}
            resizeMode="contain"
            style={{
              marginLeft: -15,
              backgroundColor: "white",
              height: 50,
              width: 50,
              borderRadius: 50,
              borderColor: "white",
              borderWidth: 4,
            }}
          />
        </View>

        <Text style={{ marginBottom: 5 }} b h4>
          Enter your credentials
        </Text>
        <Text style={{ color: "grey" }} small>
          By providing your{" "}
          <Text span b>
            {localDatabase[stepContent.dbTitle].parent_name}
          </Text>{" "}
          credentials to Plaid, you're enabling Plaid to retrieve your financial
          data.
        </Text>
        <View style={{ marginVertical: 10 }} />
        <Input width={"100%"} initialValue="desertporcupine1835">
          Username
        </Input>
        <View style={{ marginVertical: 20 }} />
        <Input.Password width={"100%"} initialValue="123456abc">
          Password
        </Input.Password>
      </View>
      <Button
        width={"100%"}
        onClick={() => {
          const resolveAfter3Sec = new Promise((resolve) =>
            setTimeout(resolve, 500)
          );
          toast.promise(resolveAfter3Sec, {
            pending: "Request pending",
            success: "Request resolved 👌",
            error: "Request rejected 🤯",
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          advanceStage();
        }}
        type="secondary-light"
      >
        Submit
      </Button>
    </View>
  );

  const step3 = () => (
    <View
      style={{
        width: "100%",
        height: "100%",
        paddingVertical: 75,
        paddingHorizontal: 20,
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <View style={{ alignItems: "center", width: "100%" }}>
        <RemoteImage desiredWidth={100} uri={plan.prospectLogo} />
        <View style={{ marginVertical: 30 }} />
        {stepContent.content.itemName != null ? (
          <View style={{ marginBottom: 10, width: "100%" }}>
            <Text style={{ alignSelf: "flex-start" }} h4>
              {stepContent.content.objectName} Identified
            </Text>
          </View>
        ) : null}
        <View
          style={{
            borderColor: "lightgrey",
            borderWidth: 0.5,
            borderRadius: 5,
            width: "100%",
            padding: 15,
          }}
        >
          <View style={{ width: "100%" }}>
            {stepContent.content.itemName == null ? (
              <View style={{ alignItems: "center", width: "100%" }}>
                <Image
                  source={require("../../../../assets/images/sheild-dynamic-premium.png")}
                  style={{
                    height: 150,
                    width: 150,
                    alignSelf: "center",
                  }}
                />
                <View style={{ marginVertical: 10 }} />
                <Text style={{ textAlign: "center", width: "70%" }} b>
                  {stepContent.content.objectName} Connected Successfully
                </Text>
              </View>
            ) : (
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <View style={{ flexDirection: "row", alignItems: "center" }}>
                  <Image
                    source={{ uri: localDatabase[stepContent.dbTitle].logo }}
                    style={{
                      height: 25,
                      width: 25,
                      borderRadius: 25,
                      marginRight: 10,
                      borderWidth: 1,
                      borderColor: "lightgrey",
                    }}
                    resizeMode="contain"
                  />

                  <Text small b>
                    {eval(stepContent.content.itemName)}
                  </Text>
                </View>
                <Ionicons
                  name="ios-checkmark-circle"
                  size={24}
                  color={plan.company.lightColor}
                />
              </View>
            )}
          </View>
        </View>
      </View>
      <Button
        width={"100%"}
        onClick={() => {
          selectTab({ index: order + 1, type: "manual" });
        }}
        type="secondary-light"
      >
        Finish
      </Button>
    </View>
  );

  return (
    <View
      style={{
        width: "100",
        alignContent: "center",
        justifyContent: "center",
      }}
    >
      <View style={{ alignSelf: "center" }}>
        {phoneElem(
          <View style={{ height: "100%", width: "100%" }}>
            {stage == 0 ? step0() : null}
            {stage == 1 ? step1() : null}
            {stage == 2 ? step2() : null}
            {stage == 3 ? step3() : null}
          </View>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  databaseView: {
    padding: 30,
    alignSelf: "center",
    borderRadius: 10,
    backgroundColor: "white",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.1,
    shadowRadius: 15,

    elevation: 9,
  },
});

export default Connect;
