import React, { useContext, useState, useEffect, useRef } from "react";
import {
  StyleSheet,
  View,
  useWindowDimensions,
  ImageBackground,
  TouchableOpacity,
  ActivityIndicator,
  Image,
} from "react-native";
import { Context as PlanContext } from "@context/PlanContext";
import { Context as LocalContext } from "@context/LocalContext";
import {
  Text,
  Input,
  Button,
  Select,
  Card,
  useBodyScroll,
} from "@geist-ui/react";
import colors from "@res/colors";
import fonts from "@res/fonts";
import StepBot from "../Endpoint/StepBot";
import Success from "@components/SuccessComponent/SuccessComponent";
import { motion } from "framer-motion";
import { Ionicons } from "@expo/vector-icons";
import { toast } from "react-toastify";
import "../Connect/devices.min.css";
import _ from "lodash";

const phoneElem = (content) => {
  return (
    <div className="marvel-device iphone-x" style={{ "--size-divisor": 1.2 }}>
      <div className="notch">
        <div className="camera"></div>
        <div className="speaker"></div>
      </div>
      <div className="top-bar"></div>
      <div className="sleep"></div>
      <div className="bottom-bar"></div>
      <div className="volume"></div>
      <div className="overflow">
        <div className="shadow shadow--tr"></div>
        <div className="shadow shadow--tl"></div>
        <div className="shadow shadow--br"></div>
        <div className="shadow shadow--bl"></div>
      </div>
      <div className="inner-shadow"></div>
      <div className="screen">{content}</div>
    </div>
  );
};

const PhoneTrigger = ({
  order,
  stepContent,
  scrollRef,
  scrollDown,
  isScrolling,
}) => {
  const {
    state: { gradients, tabs, plan },
  } = useContext(PlanContext);
  const {
    modDatabase,
    state: { localDatabase },
  } = useContext(LocalContext);
  const botRef = useRef(null);
  const successRef = useRef(null);
  const [didExecute, setDidExecute] = useState(false);
  const [triggerInView, setTriggerInView] = useState(false);
  const [executeTrigger, setExecuteTrigger] = useState(false);
  //const [scrollDown, setScrollDown] = useState(false);
  const [locked, setLocked] = useBodyScroll(scrollRef);
  const [displayTrigger, setDisplayTrigger] = useState(false);

  const topRef = useRef(null);
  const windowDims = useWindowDimensions();

  const onExecute = async () => {
    await modDatabase({
      key: tabs[order].dbTitle,
      value: _.cloneDeep(stepContent.content.payload),
    });
    botRef.current.scrollIntoView({
      behavior: "smooth",
    });
    setDidExecute(true);
  };

  const lineVariants = {
    visible: { display: "block", height: "auto", opacity: 1 },
    hidden: { display: "none", height: 0, opacity: 0 },
  };

  if (localDatabase[stepContent.content.preloadKey] == null) {
    return (
      <Card style={{ marginTop: 25 }} type="warning">
        <Text small>
          <Text b>Previous step must be completed to continue</Text>
        </Text>
      </Card>
    );
  } else {
    return (
      <View ref={topRef} style={{ width: "100%", minHeight: 1400 }}>
        <View
          style={{
            width: "80%",
            alignSelf: "center",
            alignItems: "center",
          }}
        >
          {phoneElem(
            <View
              style={{ height: "100%", width: "100%", alignSelf: "center" }}
            >
              <Image
                source={{
                  uri: "https://s3-media0.fl.yelpcdn.com/bphoto/oX37Z3uvLw6xn54PthOMPA/o.jpg",
                }}
                resizeMode="cover"
                style={{
                  height: "100%",
                  width: "100%",
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  zIndex: 0,
                }}
              />

              <Image
                source={{
                  uri: "https://i.ibb.co/hcqKvCB/Edited-Camera-Phone-Frame.png",
                }}
                resizeMode="cover"
                style={{
                  height: "100%",
                  width: "100%",
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  zIndex: 1,
                }}
              />
              <TouchableOpacity
                style={{
                  width: 75,
                  height: 75,
                  position: "absolute",
                  bottom: 0,
                  zIndex: 5,
                  alignSelf: "center",
                }}
                onPress={() => onExecute()}
              />
            </View>
          )}
        </View>

        <motion.div
          animate={{ opacity: didExecute ? 1 : 0 }}
          style={{
            alignSelf: "center",
            width: "100%",
          }}
          ref={botRef}
        >
          <View style={{ alignSelf: "center", marginTop: 20 }}>
            {didExecute ? (
              <motion.div
                variants={lineVariants}
                initial="hidden"
                animate={"visible"}
                transition={{ duration: 2 }}
                style={{ alignSelf: "center" }}
              >
                <View
                  style={{
                    width: 1,
                    height: 75,
                    borderLeftWidth: 1,
                    borderLeftColor: "grey",
                    borderStyle: "dashed",
                    alignSelf: "center",
                  }}
                />
              </motion.div>
            ) : null}
          </View>

          <View style={{ width: "100%", alignItems: "center" }}>
            <View
              style={[styles.botContainer, { background: gradients[order] }]}
            >
              <StepBot
                didExecute={didExecute}
                botDetails={stepContent.content.botDetails}
                successRef={successRef}
              />
            </View>
          </View>

          {didExecute ? (
            <View style={{ alignSelf: "center", marginTop: 20 }}>
              <motion.div
                variants={lineVariants}
                initial="hidden"
                animate={"visible"}
                transition={{ duration: 1, delay: [1, 2, 3].length * 1 + 0.5 }}
                style={{ alignSelf: "center" }}
              >
                <View
                  style={{
                    width: 1,
                    height: 75,
                    borderLeftWidth: 1,
                    borderLeftColor: "grey",
                    borderStyle: "dashed",
                    alignSelf: "center",
                  }}
                />
              </motion.div>
              <View style={{ marginVertical: 20, width: "100%" }}>
                <View
                  style={{ position: "absolute", top: -50 }}
                  ref={successRef}
                />
                <motion.div
                  variants={lineVariants}
                  onAnimationComplete={() => setLocked(false)}
                  initial="hidden"
                  animate={"visible"}
                  transition={{ duration: 1, delay: [1, 2, 3].length * 1 + 1 }}
                  style={{
                    alignSelf: "center",
                    width:
                      stepContent.content.success.componentType == 3 ||
                      stepContent.content.success.componentType == 4 ||
                      stepContent.content.success.componentType == 6
                        ? "80%"
                        : null,
                  }}
                >
                  <Success data={stepContent.content.success} />
                </motion.div>
              </View>
            </View>
          ) : null}
        </motion.div>
      </View>
    );
  }
};

const styles = StyleSheet.create({
  botContainer: {
    alignSelf: "center",
    width: "80%",
    alignItems: "center",
    justifyContent: "center",
    maxWidth: 600,
    marginTop: 20,
    marginBottom: 20,
    borderRadius: 5,
    padding: 35,
  },
  botBackground: {
    position: "absolute",
    borderRadius: 5,
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    height: "100%",
    zIndex: -1,
  },
  dashedLine: {
    alignSelf: "center",
    borderLeftWidth: 1,
    borderStyle: "dashed",
    height: 100,
    width: 0,
  },
});

export default PhoneTrigger;
