import React, { useContext, useState, useEffect, useRef } from "react";
import {
  StyleSheet,
  View,
  Image,
  ScrollView,
  useWindowDimensions,
  ActivityIndicator,
  TouchableOpacity,
} from "react-native";
import { Context as PlanContext } from "@context/PlanContext";
import { Context as LocalContext } from "@context/LocalContext";
import { useMediaQuery } from "react-responsive";
import { Text, Button, Tag, Card, Input, Dot } from "@geist-ui/react";
import colors from "@res/colors";
import fonts from "@res/fonts";
import { Ionicons } from "@expo/vector-icons";
import { motion } from "framer-motion";
import pickTextColor from "../../functions/pickTextColor";

const EntityAction = ({ content }) => {
  const {
    state: { plan },
  } = useContext(PlanContext);
  const {
    state: { localDatabase },
  } = useContext(LocalContext);
  const actionColor = pickTextColor(
    plan.company.darkColor,
    plan.company.darkColor,
    plan.company.lightColor
  );

  return (
    <View
      style={[
        styles.databaseView,
        { borderTopColor: actionColor, borderTopWidth: 3 },
      ]}
    >
      <View style={{ width: 300, justifyContent: "space-between" }}>
        <View style={{ marginBottom: 25 }}>
          <View
            style={{
              flexDirection: "row",
              alignItems: "flex-start",
              marginBottom: -10,
            }}
          >
            <View
              style={{
                height: 20,
                width: 20,
                borderRadius: 50,
                backgroundColor: plan.company.darkColor,
                alignItems: "center",
                justifyContent: "center",
                marginRight: 10,
                marginTop: 6,
              }}
            >
              <Ionicons
                name={content.headerIcon}
                size={11}
                color={plan.company.lightColor}
              />
            </View>
            <Text
              style={{
                fontFamily:
                  plan.company.headingFont != null
                    ? plan.company.headingFont
                    : null,
              }}
              h4
            >
              {eval(content.header)}
            </Text>
          </View>
          <Text style={{ color: "grey" }} font="11px">
            {eval(content.subHeader)}
          </Text>
          <View style={{ alignSelf: "flex-start" }}>
            <Tag
              type="success"
              style={{
                backgroundColor: plan.company.darkColor,
                color: plan.company.lightColor,
                alignSelf: "flex-start",
              }}
              invert
            >
              {eval(content.statusLabel)}
            </Tag>
          </View>
        </View>

        <Card style={{ width: "100%", alignSelf: "center", marginBottom: 25 }}>
          <View
            style={{
              width: "100%",
              alignItems: "center",
              alignSelf: "center",

              marginBottom: 25,
            }}
          >
            <Image
              source={{
                uri: eval(content.actionImage),
              }}
              resizeMode="contain"
              style={{
                height: 50,
                width: 50,
                borderRadius: 50,
                backgroundColor: "white",
                marginBottom: 15,
                alignItems: "center",
                justifyContent: "center",
              }}
            />
            <Text style={{ color: "grey" }} small b font="12px">
              {eval(content.actionSubHeader)}
            </Text>
            <Text
              style={{
                marginBottom: -10,
                textAlign: "center",
                fontFamily:
                  plan.company.headingFont != null
                    ? plan.company.headingFont
                    : null,
                color: actionColor,
              }}
              h4
            >
              {eval(content.actionHeader)}
            </Text>
          </View>
          <TouchableOpacity
            style={{
              backgroundColor: plan.company.darkColor,
              width: "100%",
              paddingVertical: 8,
              alignItems: "center",
              justifyContent: "space-between",
              borderRadius: 5,
              flexDirection: "row",
              paddingHorizontal: 15,
            }}
          >
            <Ionicons
              name="ios-checkmark-circle"
              size={24}
              color={plan.company.lightColor}
            />
            <Text style={{ color: plan.company.lightColor }} small b>
              {eval(content.successLabel)}
            </Text>
            <View />
          </TouchableOpacity>
        </Card>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  databaseView: {
    padding: 30,
    alignSelf: "center",
    borderRadius: 10,
    backgroundColor: "white",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.1,
    shadowRadius: 15,
    elevation: 9,
  },
});

export default EntityAction;
