import React, { useContext, useState, useEffect, useRef } from "react";
import {
  StyleSheet,
  View,
  ScrollView,
  useWindowDimensions,
  ActivityIndicator,
} from "react-native";
import { Context as PlanContext } from "@context/PlanContext";
import { Context as LocalContext } from "@context/LocalContext";
import { useMediaQuery } from "react-responsive";
import { LinearGradient } from "expo-linear-gradient";
import { Text, Button, Tag } from "@geist-ui/react";
import colors from "@res/colors";
import fonts from "@res/fonts";
import { Ionicons } from "@expo/vector-icons";
import { motion } from "framer-motion";
import _ from "lodash";
import moment from "moment";
import pickTextColor from "../../../functions/pickTextColor";

const StepBot = ({ didExecute, botDetails, successRef }) => {
  const {
    state: { plan },
  } = useContext(PlanContext);
  const {
    state: { localDatabase },
  } = useContext(LocalContext);
  const lastStepRef = useRef(null);
  const [stepsCompleted, setStepsCompleted] = useState(1);
  const window = useWindowDimensions();
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1024 });
  const isMobile = useMediaQuery({ maxWidth: 600 });
  const actionColor = pickTextColor(
    plan.company.darkColor,
    plan.company.lightColor,
    plan.company.darkColor
  );

  useEffect(() => {
    if (didExecute == true) setStepsCompleted(1);
  }, [didExecute]);

  const loadingVariants = {
    visible: { display: "block", opacity: 1 },
    hidden: { display: "none", opacity: 0 },
  };

  const finishBotAnimation = () => {
    if (lastStepRef.current != null) {
      console.log("Scrolling Past Bot Container");
      sub: [
        {
          key: "Status",
          value: "Verified",
        },
      ],
        successRef.current.scrollIntoView({
          behavior: "smooth",
        });
    }
  };

  const stepComp = (text, color, delay) => {
    return (
      <motion.div whileHover={{ scale: 1.05 }}>
        <View style={styles.stepContainer}>
          <View
            style={{
              height: 20,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <motion.div
              variants={loadingVariants}
              initial="visible"
              animate={"hidden"}
              transition={{ delay: delay + 1 }}
            >
              <ActivityIndicator color={color} style={{ marginRight: 10 }} />
            </motion.div>
            <motion.div
              variants={loadingVariants}
              initial="hidden"
              animate={"visible"}
              transition={{ delay: delay + 1 }}
            >
              <Ionicons
                name="checkmark-circle"
                size={22}
                color={color}
                style={{ marginRight: 10 }}
              />
            </motion.div>
          </View>

          <Text style={{ color: "white", lineHeight: 1.2 }} p>
            {text}
          </Text>
        </View>
      </motion.div>
    );
  };

  const subStepComp = (key, value, color) => (
    <View
      style={[styles.subStepContainer, { marginLeft: 25, marginVertical: 6 }]}
      key={`${key}${value}`}
    >
      <Text style={{ color: "#C4C4C4" }} small>
        {key}
      </Text>
      <Text style={{ color: "white" }} small>
        {value.length > 20 ? `${value.substring(0, 20)}...` : value}
      </Text>
    </View>
  );

  const subComp = (subSteps, color) => (
    <View style={{ marginTop: -3, marginBottom: -3 }}>
      <View style={[styles.subContainer, { borderLeftColor: color }]}>
        {subSteps}
      </View>
    </View>
  );

  const stepVariants = {
    visible: { height: "auto", opacity: 1 },
    hidden: { height: 0, opacity: 0 },
  };

  return (
    <View
      style={[
        styles.overviewBox,
        {
          background:
            "linear-gradient(" +
            120 +
            "deg, " +
            "black" +
            ", " +
            "#262626" +
            ")",
        },
      ]}
    >
      <View style={{ flexDirection: "row" }}>
        <Ionicons
          name="logo-codepen"
          size={20}
          color={actionColor}
          style={{ marginRight: 8 }}
        />
        <Text style={{ color: "white" }} h5>
          {plan.company.name} API
        </Text>
      </View>

      <Text style={{ color: "grey" }} small>
        Processing Step: {stepsCompleted}/{botDetails.length + 1}
      </Text>

      <View style={{ marginVertical: 10 }} />
      <View style={{ borderTopWidth: 1, borderTopColor: "#303030" }} />
      <View style={{ marginTop: 15 }} />
      {stepComp("Request Received", actionColor)}
      {subComp(<View style={{ height: 15 }} />, actionColor)}

      {didExecute ? (
        <View>
          {botDetails.map((elem, index) => {
            const delay =
              index > 0 &&
              botDetails[index - 1].sub != null &&
              botDetails[index - 1].length > 0
                ? 0.5 * index + 1
                : 1 * index;
            return (
              <View key={`bot-${elem.title}`}>
                <motion.div
                  variants={stepVariants}
                  initial="hidden"
                  animate={"visible"}
                  onAnimationComplete={() =>
                    setStepsCompleted(stepsCompleted + 1)
                  }
                  transition={{ duration: 1, delay: delay }}
                >
                  {stepComp(
                    elem.preloadTitle != null
                      ? eval(elem.preloadTitle)
                      : elem.title,
                    actionColor,
                    delay
                  )}
                </motion.div>

                {elem.sub != null && elem.sub.length > 0 ? (
                  <motion.div
                    variants={stepVariants}
                    initial="hidden"
                    animate={"visible"}
                    transition={{ duration: 2, delay: delay + 0.5 }}
                  >
                    {subComp(
                      <View>
                        {elem.sub.map((subElem) =>
                          subStepComp(
                            subElem.preloadKey != null
                              ? eval(subElem.preloadKey)
                              : subElem.key,
                            subElem.preloadValue != null
                              ? eval(subElem.preloadValue)
                              : subElem.value
                          )
                        )}
                      </View>,
                      actionColor
                    )}
                  </motion.div>
                ) : (
                  <motion.div
                    variants={stepVariants}
                    initial="hidden"
                    animate={"visible"}
                    transition={{ duration: 2, delay: delay + 0.5 }}
                  >
                    {subComp(<View style={{ height: 15 }} />, actionColor)}
                  </motion.div>
                )}
              </View>
            );
          })}
          <motion.div
            ref={lastStepRef}
            onAnimationComplete={() =>
              setTimeout(() => {
                finishBotAnimation();
              }, 800)
            }
            variants={stepVariants}
            initial="hidden"
            animate={"visible"}
            transition={{
              duration: 1,
              delay: botDetails.length * 1,
            }}
          >
            <Tag
              style={{
                backgroundColor: actionColor,
                color: "black",
                marginTop: 25,
              }}
              type="default"
              invert
            >
              Successful Request 🎉
            </Tag>
          </motion.div>
        </View>
      ) : null}
    </View>
  );
};

const styles = StyleSheet.create({
  keyField: {
    backgroundColor: "black",
    paddingHorizontal: 20,
    paddingVertical: 12,
    borderRadius: 6,
    marginTop: 10,
    width: "100%",
  },
  overviewBox: {
    width: "100%",
    padding: 40,
    borderRadius: 6,
    alignSelf: "center",
  },
  boxBackground: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: -1,
    borderRadius: 6,
  },
  goalContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginVertical: 2,
  },
  goalCircle: {
    height: 18,
    width: 18,
    borderRadius: 100,
    marginRight: 10,
  },
  stepSquare: {
    height: 8,
    width: 8,
    marginRight: 12,
  },
  stepContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  subStepContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  subContainer: {
    borderLeftWidth: 2.5,
    marginLeft: 8.5,
  },
});

export default StepBot;
