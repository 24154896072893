import React, { useContext, useState, useEffect, useRef } from "react";
import {
  StyleSheet,
  View,
  ScrollView,
  useWindowDimensions,
  ActivityIndicator,
  TouchableOpacity,
  Image,
} from "react-native";
import { Context as PlanContext } from "@context/PlanContext";
import { useMediaQuery } from "react-responsive";
import { Text, Button, Tag, Card, Input, Table } from "@geist-ui/react";
import colors from "@res/colors";
import fonts from "@res/fonts";
import { Ionicons } from "@expo/vector-icons";
import { motion } from "framer-motion";
import JsxParser from "react-jsx-parser";
import RemoteImage from "../../../../../components/RemoteImage";
import Success from "@components/SuccessComponent/SuccessComponent";
import pickTextColor from "../../../../../functions/pickTextColor";
import GoalFlow from "./GoalFlow";

const Goal = ({ order }) => {
  const {
    state: { plan, tabs },
  } = useContext(PlanContext);
  const scrollRef = useRef(null);
  const [animating, setAnimating] = useState(false);
  const actionColor = pickTextColor(
    plan.company.darkColor,
    plan.company.darkColor,
    plan.company.lightColor
  );

  const goalContent = tabs[order];

  const successData = [
    {
      order: 3,
      componentType: 0, // Entity
      visualView: 1,
      headerIcon: "briefcase",
      header: `"Counterparty Created"`,
      subHeader: `"Ready to Receive Payments"`,
      successLabel: `"Counterparty Linked"`,
      data: [
        {
          key: "New Counterparty",
          value: `"Bungalow"`,
        },
      ],
    },
    {
      componentType: 2, // Entity
      connectingString: `"$10.00"`,
      data: [
        {
          entityImage: `"https://res.cloudinary.com/crunchbase-production/image/upload/c_lpad,f_auto,q_auto:eco,dpr_1/ghocsipwbmjw2vvwe7ju"`,
          entitySubHeader: `"Originating Account"`,
          entityHeader: `"Pipe"`,
          entityBody: `"Sending calculated amount to fund company"`,
          successLabel: `"Outgoing Funds"`,
        },
        {
          entityImage: `"https://res.cloudinary.com/expertise-com/image/upload/f_auto,fl_lossy,q_auto/w_167,c_scale/remote_media/logos/property-management-san-francisco-bungalow-com.jpg"`,
          entitySubHeader: `"Receiving Account"`,
          entityHeader: `"Bungalow"`,
          successLabel: `"Received Funds"`,
        },
      ],
    },
    {
      order: 5,
      componentType: 0, // Entity
      visualView: 1,
      headerIcon: "alarm",
      header: `"Payment Successful"`,
      subHeader: `"Delivered to: Bungalow"`,
      successLabel: `"Funds Delivered"`,
      data: [
        {
          key: "Payment Processed",
          value: `"$40,342.45"`,
        },
      ],
    },
  ];

  const componentVariants = {
    visible: { opacity: 1 },
    hidden: { opacity: 0 },
  };

  const scrollContent = (
    <ScrollView
      ref={scrollRef}
      horizontal
      contentContainerStyle={styles.animationContainer}
    >
      <motion.div
        style={{
          alignSelf: "center",
          height: 500,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <View
          style={[
            styles.databaseView,
            {
              padding: 30,
              justifyContent: "center",
              alignSelf: "center",
              borderTopColor: actionColor,
              borderTopWidth: 3,
            },
          ]}
        >
          <Image
            source={require("../../../../../../assets/images/lock-dynamic-premium.png")}
            style={{
              height: 200,
              width: 200,
              alignSelf: "center",
            }}
          />
          <View style={{ marginVertical: 10 }} />
          <TouchableOpacity
            style={{
              backgroundColor: plan.company.darkColor,
              width: "100%",
              paddingVertical: 8,
              alignItems: "center",
              justifyContent: "space-between",
              borderRadius: 5,
              flexDirection: "row",
              paddingHorizontal: 15,
            }}
          >
            <Ionicons
              name="ios-checkmark-circle"
              size={24}
              color={plan.company.lightColor}
            />
            <Text style={{ color: plan.company.lightColor }} small b>
              Authenticate
            </Text>
            <View />
          </TouchableOpacity>
        </View>
      </motion.div>

      <motion.div
        variants={componentVariants}
        initial="hidden"
        animate={animating ? "visible" : "hidden"}
        style={{ alignSelf: "center" }}
      >
        <View style={styles.nextBorder} />
      </motion.div>

      <motion.div
        variants={componentVariants}
        initial="hidden"
        animate={animating ? "visible" : "hidden"}
        style={{ alignSelf: "center", height: 500 }}
        transition={{ delay: 0.2 }}
      >
        <Success data={successData[0]} />
      </motion.div>

      <motion.div
        variants={componentVariants}
        initial="hidden"
        animate={animating ? "visible" : "hidden"}
        style={{ alignSelf: "center" }}
        transition={{ delay: 1.2 }}
      >
        <View style={styles.nextBorder} />
      </motion.div>
      <motion.div
        variants={componentVariants}
        initial="hidden"
        animate={animating ? "visible" : "hidden"}
        style={{ alignSelf: "center", height: 600 }}
        transition={{ delay: 1.2 }}
      >
        <Success data={successData[1]} />
      </motion.div>

      <motion.div
        variants={componentVariants}
        initial="hidden"
        animate={animating ? "visible" : "hidden"}
        style={{ alignSelf: "center" }}
        transition={{ delay: 1.8 }}
      >
        <View style={styles.nextBorder} />
      </motion.div>

      <motion.div
        onAnimationComplete={() => {
          if (animating)
            scrollRef.current.scrollBy({
              top: 0,
              left: +1000,
              behavior: "smooth",
            });
        }}
        variants={componentVariants}
        initial="hidden"
        animate={animating ? "visible" : "hidden"}
        transition={{ delay: 1.8 }}
        style={{ alignSelf: "center", height: 500 }}
      >
        <Success data={successData[2]} height={500} />
      </motion.div>
    </ScrollView>
  );

  return (
    <View style={styles.container}>
      <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
        <View style={{ width: "100%" }}>
          <Text
            style={{
              color: plan.company.headingColor,
              marginBottom: -10,
              fontFamily:
                plan.company.headingFont != null
                  ? plan.company.headingFont
                  : null,
            }}
            h1
          >
            Mission
          </Text>
          <Text
            style={{
              color: pickTextColor(
                plan.company.darkColor,
                plan.company.darkColor,
                plan.company.lightColor
              ),
              marginBottom: -10,
              fontFamily:
                plan.company.headingFont != null
                  ? plan.company.headingFont
                  : null,
            }}
            h1
          >
            {goalContent.title}
          </Text>
          <View style={{ marginVertical: 15 }} />
          <Text
            style={{
              color: pickTextColor(
                plan.company.darkColor,
                plan.company.darkColor,
                "#292929"
              ),
            }}
            font="16px"
          >
            {goalContent.description}
          </Text>
          <View style={{ marginVertical: 10 }} />
          {false ? (
            <View style={{ alignSelf: "flex-start" }}>
              <Button
                style={{ fontWeight: "bold" }}
                type="secondary"
                onClick={() =>
                  // lastComp.current.scrollIntoView({
                  //   behavior: "smooth",
                  // })
                  {
                    setAnimating(true);
                  }
                }
              >
                Start Animation
              </Button>
            </View>
          ) : null}
        </View>
      </View>
      <View style={{ marginVertical: 20 }} />
      <View style={styles.scrollContainer}>
        {false ? scrollContent : null}
        <View style={{ height: 400 }}>
          <GoalFlow />
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: "100%",
  },
  scrollContainer: {
    borderWidth: 1,
    borderColor: "lightgrey",
    borderRadius: 10,
    marginBottom: 100,
  },
  animationContainer: {
    flexDirection: "row",
    justifyContent: "space-around",
    minWidth: "100%",
    paddingVertical: 40,
    paddingHorizontal: 25,
  },
  databaseView: {
    padding: 30,
    alignSelf: "center",
    borderRadius: 10,
    backgroundColor: "white",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.1,
    shadowRadius: 15,

    elevation: 9,
  },
  nextBorder: {
    height: 0,
    width: 75,
    borderTopWidth: 1,
    borderTopColor: "grey",
    borderStyle: "dashed",
    alignSelf: "center",
    marginHorizontal: 20,
  },
  // ...JSON.parse(loadedStyles),
});

export default Goal;
