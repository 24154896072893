import React, { useContext, useState, useEffect, useRef } from "react";
import {
  StyleSheet,
  View,
  Image,
  ScrollView,
  TouchableOpacity,
} from "react-native";
import { Context as PlanContext } from "@context/PlanContext";
import { Context as LocalContext } from "@context/LocalContext";
import { Text, Input, Button, Snippet } from "@geist-ui/react";
import colors from "@res/colors";
import fonts from "@res/fonts";
import { Ionicons } from "@expo/vector-icons";
import { motion } from "framer-motion";
import { toast } from "react-toastify";

const MemoImage = React.memo(function ({ source, style }) {
  return <Image source={source} style={style} />;
});

const MultiSelect = ({ order, stepContent }) => {
  const {
    selectTab,
    state: { plan },
  } = useContext(PlanContext);
  const {
    modDatabase,
    state: { localDatabase },
  } = useContext(LocalContext);
  const [stage, setStage] = useState(0);

  const onSelect = (merchant) => {
    if (stage + 1 < stepContent.content.stages.length) setStage(stage + 1);
    modDatabase({
      key: stepContent.dbTitle,
      value: {
        ...localDatabase[stepContent.dbTitle],
        [stepContent.content.stages[stage]]: merchant,
      },
    });
  };

  return (
    <View style={{ width: "100", minHeight: 500 }}>
      <View
        style={[
          styles.databaseView,
          {
            borderTopColor: plan.company.darkColor,
            borderTopWidth: 3,
            width: "80%",
            maxWidth: 600,
          },
        ]}
      >
        <View style={{ marginBottom: 10 }}>
          <View
            style={{
              flexDirection: "row",
              alignItems: "flex-start",
              marginBottom: -10,
            }}
          >
            <Ionicons
              name={stepContent.content.icon}
              size={25}
              color={plan.company.darkColor}
              style={{ marginRight: 8 }}
            />
            <Text
              style={{
                fontFamily:
                  plan.company.headingFont != null
                    ? plan.company.headingFont
                    : null,
                color: plan.company.darkColor,
              }}
              h3
            >
              {stepContent.content.actionHeading}
            </Text>
          </View>
          <Text style={{ color: plan.company.darkColor }} font="15px">
            {stepContent.content.actionSubheading}
          </Text>
        </View>

        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <TouchableOpacity onPress={() => setStage(0)}>
            <Text style={{ color: stage == 0 ? "black" : "grey" }}>
              {localDatabase[stepContent.dbTitle] != null &&
              localDatabase[stepContent.dbTitle].carrier != null
                ? `1: ${localDatabase[stepContent.dbTitle].carrier.name}`
                : "1: Choose Carrier"}
            </Text>
          </TouchableOpacity>
          <View style={{ marginHorizontal: 10 }} />
          <TouchableOpacity onPress={() => setStage(1)}>
            <Text style={{ color: stage == 1 ? "black" : "grey" }}>
              {localDatabase[stepContent.dbTitle] != null &&
              localDatabase[stepContent.dbTitle].plan != null
                ? `2: ${localDatabase[stepContent.dbTitle].plan.name}`
                : "2: Choose Plan"}
            </Text>
          </TouchableOpacity>
        </View>
        <View
          style={{
            borderColor: "lightgrey",
            borderWidth: 0.5,
            borderRadius: 5,
            marginBottom: 40,
          }}
        >
          <ScrollView
            showsVerticalScrollIndicator={false}
            contentContainerStyle={{
              flexDirection: "row",
              justifyContent: "space-between",
              flexWrap: "wrap",
              maxHeight: 300,
              paddingVertical: 15,
              paddingHorizontal: 15,
              alignItems: "flex-start",
            }}
          >
            {stepContent.content.data[stage].map((elem) => {
              const isSelected =
                localDatabase[stepContent.dbTitle] != null &&
                `${localDatabase[stepContent.dbTitle].id}` == `${elem.id}`;

              return (
                <TouchableOpacity
                  key={`${elem.id}`}
                  onPress={() => onSelect(elem)}
                  style={{
                    marginVertical: 15,
                    width: "100%",
                    paddingHorizontal: 20,
                    alignItems: "center",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  {isSelected ? (
                    <Text
                      small
                      style={{
                        color: plan.company.darkColor,
                      }}
                      font="15px"
                      b
                    >
                      {elem.name}
                    </Text>
                  ) : (
                    <Text small font="15px">
                      {elem.name}
                    </Text>
                  )}
                  <Ionicons
                    name={"arrow-forward"}
                    size={20}
                    color={plan.company.darkColor}
                    style={{ marginRight: 8 }}
                  />
                </TouchableOpacity>
              );
            })}
          </ScrollView>
        </View>
        {localDatabase[stepContent.dbTitle] != null &&
        localDatabase[stepContent.dbTitle][
          stepContent.content.stages[stepContent.content.stages.length - 1]
        ] != null ? (
          <Snippet
            symbol=">"
            text={`insurance_ids=${
              localDatabase[stepContent.dbTitle][
                stepContent.content.stages[
                  stepContent.content.stages.length - 1
                ]
              ].id
            }`}
            type="dark"
            width="100%"
          />
        ) : null}
        <View style={{ marginVertical: 10 }} />
        {!localDatabase[stepContent.dbTitle] ||
        localDatabase[stepContent.dbTitle] == null ||
        localDatabase[stepContent.dbTitle].plan == null ||
        localDatabase[stepContent.dbTitle].carrier == null ? (
          <Button style={{ fontWeight: "bold" }} disabled>
            No {stepContent.content.objectName} Selected
          </Button>
        ) : (
          <Button
            width={"100%"}
            style={{
              padding: 0,
              backgroundColor: plan.company.darkColor,
              borderColor: plan.company.darkColor,
            }}
            type="secondary-light"
            onClick={() => {
              selectTab({ index: order + 1, type: "manual" });
            }}
          >
            <Text style={{ color: plan.company.lightColor }} b>
              {localDatabase[stepContent.dbTitle].carrier.name} -{" "}
              {localDatabase[stepContent.dbTitle].plan.name} Selected
            </Text>
          </Button>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  databaseView: {
    padding: 30,
    alignSelf: "center",
    borderRadius: 10,
    backgroundColor: "white",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.1,
    shadowRadius: 15,

    elevation: 9,
  },
});

export default MultiSelect;
