import React from "react";
import { StyleSheet } from "react-native";
import Entity from "./Entity";
import EntityAction from "./EntityAction";
import MultiAction from "./MultiAction";
import BarGraph from "./BarGraph";
import PieGraph from "./PieGraph";
import PhoneDisplay from "./PhoneDisplay";
import DataList from "./DataList";

const SuccessComponent = ({ data, height }) => {
  switch (data.componentType) {
    case 0:
      return <Entity content={data} />;
    case 1:
      return <EntityAction content={data} />;
    case 2:
      return <MultiAction content={data} />;
    case 3:
      return <BarGraph content={data} height={height} />;
    case 4:
      return <PieGraph content={data} height={height} />;
    case 5:
      return <PhoneDisplay content={data} />;
    case 6:
      return <DataList content={data} />;
  }
};

const styles = StyleSheet.create({});

export default SuccessComponent;
