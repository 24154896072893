import createDataContext from "./createDataContext";
import serverApi from "../api/server";
import * as RootNavigation from "../RootNavigation";
import * as Sentry from "sentry-expo";
import _ from "lodash";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { toast } from "react-toastify";

const onErr = async (err, route) => {
  console.log(`${route} error`);
  console.log(err);
  const userIdentification = await AsyncStorage.getItem("userId");
  Sentry.Browser.configureScope(function (scope) {
    scope.setExtra("function_name", route);
    scope.setExtra("id", userIdentification);
    Sentry.Browser.captureException(err);
  });
  toast.error("Having trouble connecting to the server... Try again in a few.");
};

const codeReducer = (state, action) => {
  switch (action.type) {
    case "convert_request":
      return {
        ...state,
        convertedRequest: action.payload,
      };
    case "execute_request":
      return {
        ...state,
        requestResponse: action.payload,
      };
    default:
      return state;
  }
};

const convertRequest =
  (dispatch) =>
  async ({ requestCode, language, order }) => {
    try {
      const response = await serverApi.post("/convert-request", {
        requestCode,
        language,
      });
      dispatch({
        type: "convert_request",
        payload: { code: response.data, order: order },
      });
    } catch (err) {
      onErr(err, "convertRequest");
    }
  };

const setConvertedRequest = (dispatch) => (code) => {
  dispatch({ type: "convert_request", payload: code });
};

const executeRequest =
  (dispatch) =>
  async ({ requestCode, localTitle }) => {
    try {
      const response = await serverApi.post("/execute", {
        requestCode,
      });

      /******
       *
       * If you have no path in your logic that allows one person to publish code to be used by others then you can use eval() as it is.
       * Your situation is not anyhow different from any user that has browser and dev tools in it where he/she can run any code they want.
       *
       */
      // console.log(conversionResponse.data);
      // const response = await eval(conversionResponse.data);
      // const data = await response.json();
      // console.log(data);
      // console.log(response);

      const data = response.data;

      if (!data || response.status != 200) {
        // toast.error(
        //   "Looks like something went wrong. Try again or contact support if problem persists."
        // );
        // toast("Easy, huh? 😎");
      } else {
        // toast("Easy, huh? 😎");
      }

      dispatch({
        type: "execute_request",
        payload: { ...data, localTitle: localTitle },
      });
    } catch (err) {
      onErr(err, "executeRequest");
    }
  };

export const { Provider, Context } = createDataContext(
  codeReducer,
  {
    convertRequest,
    setConvertedRequest,
    executeRequest,
  },
  {
    convertedRequest: null,
    requestResponse: null,
  }
);
