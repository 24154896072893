import React, { useContext, useState, useEffect, useRef } from "react";
import {
  StyleSheet,
  View,
  Image,
  ScrollView,
  TouchableOpacity,
  ActivityIndicator,
} from "react-native";
import { Context as PlanContext } from "@context/PlanContext";
import { Context as LocalContext } from "@context/LocalContext";
import { Context as CodeContext } from "@context/CodeContext";
import { Text, Input, Button, Snippet } from "@geist-ui/react";
import colors from "@res/colors";
import fonts from "@res/fonts";
import { Ionicons } from "@expo/vector-icons";
import { motion } from "framer-motion";
import { toast } from "react-toastify";
import axios from "axios";
import _ from "lodash";

const cutString = (str) => {
  if (str.length > 20) {
    return `${str.substring(0, 20)}...`;
  } else {
    return str;
  }
};

const MultiSelectInput = ({ order, stepContent }) => {
  const {
    selectTab,
    state: { plan },
  } = useContext(PlanContext);
  const {
    modDatabase,
    state: { localDatabase },
  } = useContext(LocalContext);
  const { executeRequest } = useContext(CodeContext);
  const [stage, setStage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [searchVal, setSearchVal] = useState("");
  const [data, setData] = useState(null);

  const cleanSetData = () => {
    try {
      const displayData = eval(stepContent.content.stageData[stage]);

      const cleanedData = _.uniqBy(
        displayData,
        stepContent.content.stageKeys[stage]
      );
      setData(cleanedData);
    } catch (e) {
      setData([]);
    }
  };

  const checkStageKey = (index) => {
    if (!Array.isArray(stepContent.content.stages[index])) {
      if (
        localDatabase[stepContent.dbTitle] &&
        localDatabase[stepContent.dbTitle][stepContent.content.stages[index]] &&
        localDatabase[stepContent.dbTitle][stepContent.content.stages[index]][
          stepContent.content.stageKeys[index]
        ]
      ) {
        return localDatabase[stepContent.dbTitle][
          stepContent.content.stages[index]
        ][stepContent.content.stageKeys[index]];
      } else {
        return null;
      }
    }

    const arrKeys = stepContent.content.stages[index];
    let returnVal = null;

    for (let i = 0; i < arrKeys.length; i++) {
      const currKey = arrKeys[i];
      if (
        localDatabase[stepContent.dbTitle] &&
        localDatabase[stepContent.dbTitle][currKey] &&
        localDatabase[stepContent.dbTitle][currKey][
          stepContent.content.stageKeys[index]
        ]
      )
        returnVal =
          localDatabase[stepContent.dbTitle][currKey][
            stepContent.content.stageKeys[index]
          ];
    }

    return returnVal;
  };

  useEffect(() => {
    // Watch for search results incoming
    const handleIncomingSearch = async () => {
      if (localDatabase[stepContent.content.searchDbTitle] != null) {
        cleanSetData();
        setIsLoading(false);
      }
    };
    if (isLoading) handleIncomingSearch();
  }, [localDatabase]);

  useEffect(() => {
    if (localDatabase[stepContent.content.searchDbTitle] != null)
      cleanSetData();
  }, [stage]);

  const onSelect = (merchant) => {
    if (stage == 0)
      searchRequest(merchant[stepContent.content.stageKeys[stage]]);

    const stageKey = !Array.isArray(stepContent.content.stages[stage])
      ? stepContent.content.stages[stage]
      : merchant.type;

    modDatabase({
      key: stepContent.dbTitle,
      value:
        stage != 0
          ? {
              ...localDatabase[stepContent.dbTitle],
              [stageKey]: merchant,
            }
          : {
              [stageKey]: merchant,
            },
    });
    if (stage + 1 < stepContent.content.stages.length) setStage(stage + 1);
  };

  const searchRequest = async (key) => {
    setIsLoading(true);
    const req = stepContent.content.searchRequest.replace(
      "<REPLACE>",
      key != null ? key : searchVal
    );
    await executeRequest({
      requestCode: req,
      localTitle: stepContent.content.searchDbTitle,
    });
  };

  return (
    <View style={{ width: "100", minHeight: 500 }}>
      <View
        style={[
          styles.databaseView,
          {
            borderTopColor: plan.company.darkColor,
            borderTopWidth: 3,
            width: "80%",
            maxWidth: 600,
          },
        ]}
      >
        <View style={{ marginBottom: 10 }}>
          <View
            style={{
              flexDirection: "row",
              alignItems: "flex-start",
              marginBottom: -10,
            }}
          >
            <Ionicons
              name={stepContent.content.icon}
              size={25}
              color={plan.company.darkColor}
              style={{ marginRight: 8 }}
            />
            <Text
              style={{
                fontFamily:
                  plan.company.headingFont != null
                    ? plan.company.headingFont
                    : null,
                color: plan.company.darkColor,
              }}
              h3
            >
              {stepContent.content.actionHeading}
            </Text>
          </View>
          <Text style={{ color: plan.company.darkColor }} font="15px">
            {stepContent.content.actionSubheading}
          </Text>
        </View>

        <View style={{ flexDirection: "row" }}>
          <Input
            onChange={(e) => setSearchVal(e.target.value)}
            width={"100%"}
            placeholder={`Find a ${stepContent.content.searchString}...`}
          />
          <View style={{ marginHorizontal: 5 }} />
          <Button
            auto
            type="secondary"
            onClick={() => {
              if (!isLoading) searchRequest();
            }}
          >
            <View
              style={{
                width: 60,
                height: 40,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {isLoading ? <ActivityIndicator /> : <Text>Search</Text>}
            </View>
          </Button>
        </View>

        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <TouchableOpacity onPress={() => setStage(0)}>
            <Text style={{ color: stage == 0 ? "black" : "grey" }}>
              {localDatabase[stepContent.dbTitle] != null &&
              localDatabase[stepContent.dbTitle][
                stepContent.content.stages[0]
              ] != null
                ? `1: ${cutString(
                    localDatabase[stepContent.dbTitle][
                      stepContent.content.stages[0]
                    ][stepContent.content.stageKeys[0]]
                  )}`
                : `1: Choose ${stepContent.content.stages[0]}`}
            </Text>
          </TouchableOpacity>
          <View style={{ marginHorizontal: 10 }} />
          <TouchableOpacity onPress={() => setStage(1)}>
            <Text style={{ color: stage == 1 ? "black" : "grey" }}>
              {localDatabase[stepContent.dbTitle] != null &&
              checkStageKey(1) != null
                ? `2: ${cutString(checkStageKey(1))}`
                : `2: Choose ${stepContent.content.stages[1]}`}
            </Text>
          </TouchableOpacity>
        </View>

        <View
          style={{
            borderColor: "lightgrey",
            borderWidth: 0.5,
            borderRadius: 5,

            marginBottom: 40,
          }}
        >
          {localDatabase[stepContent.content.searchDbTitle] != null &&
          data != null ? (
            <ScrollView
              showsVerticalScrollIndicator={false}
              contentContainerStyle={{
                flexDirection: "row",
                justifyContent: "space-between",
                flexWrap: "wrap",
                maxHeight: 300,
                paddingVertical: 15,
                paddingHorizontal: 15,
                alignItems: "flex-start",
              }}
            >
              {data.map((elem, index) => {
                const isSelected =
                  localDatabase[stepContent.dbTitle] != null &&
                  `${
                    localDatabase[stepContent.dbTitle][
                      stepContent.content.idKey
                    ]
                  }` == `${elem[stepContent.content.idKey]}`;

                const displayValue = _.get(
                  elem,
                  stepContent.content.stageKeys[stage]
                );

                return (
                  <TouchableOpacity
                    key={`${elem[stepContent.content.idKey]}`}
                    onPress={() => onSelect(elem)}
                    style={{
                      marginVertical: 15,
                      width: "100%",
                      paddingHorizontal: 20,
                      alignItems: "center",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    {isSelected ? (
                      <Text
                        small
                        style={{
                          color: plan.company.darkColor,
                        }}
                        font="15px"
                        b
                      >
                        {displayValue}
                      </Text>
                    ) : (
                      <Text small font="15px">
                        {displayValue}
                      </Text>
                    )}
                    <Ionicons
                      name={"arrow-forward"}
                      size={20}
                      color={plan.company.darkColor}
                      style={{ marginRight: 8 }}
                    />
                  </TouchableOpacity>
                );
              })}
            </ScrollView>
          ) : (
            <View style={{ width: "100%", marginVertical: 40 }}>
              <Text style={{ color: "grey", textAlign: "center" }} small>
                Enter a {stepContent.content.searchString} above
              </Text>
            </View>
          )}
        </View>
        {localDatabase[stepContent.dbTitle] != null &&
        localDatabase[stepContent.dbTitle][
          stepContent.content.stages[stepContent.content.stages.length - 1]
        ] != null ? (
          <Snippet
            symbol=">"
            text={`${stepContent.content.snippetKey}=${
              localDatabase[stepContent.dbTitle][
                stepContent.content.stages[
                  stepContent.content.stages.length - 1
                ]
              ][stepContent.content.idKey]
            }`}
            type="dark"
            width="100%"
          />
        ) : null}
        <View style={{ marginVertical: 10 }} />
        {!localDatabase[stepContent.dbTitle] ||
        localDatabase[stepContent.dbTitle] == null ||
        localDatabase[stepContent.dbTitle][stepContent.content.stages[0]] ==
          null ||
        checkStageKey(1) == null ? (
          <Button style={{ fontWeight: "bold" }} disabled>
            No {stepContent.content.objectName} Selected
          </Button>
        ) : (
          <Button
            width={"100%"}
            style={{
              padding: 0,
              backgroundColor: plan.company.darkColor,
              borderColor: plan.company.darkColor,
            }}
            type="secondary-light"
            onClick={() => {
              selectTab({ index: order + 1, type: "manual" });
            }}
          >
            <Text style={{ color: plan.company.lightColor }} b>
              {cutString(
                localDatabase[stepContent.dbTitle][
                  stepContent.content.stages[0]
                ][stepContent.content.stageKeys[0]]
              )}{" "}
              - {cutString(checkStageKey(1))} Selected
            </Text>
          </Button>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  databaseView: {
    padding: 30,
    alignSelf: "center",
    borderRadius: 10,
    backgroundColor: "white",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.1,
    shadowRadius: 15,

    elevation: 9,
  },
});

export default MultiSelectInput;
