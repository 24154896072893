import React, { useContext, useState, useEffect, useRef } from "react";
import {
  StyleSheet,
  View,
  useWindowDimensions,
  ActivityIndicator,
  ScrollView,
  TouchableOpacity,
  Image,
} from "react-native";
import { Context as PlanContext } from "@context/PlanContext";
import { Context as LocalContext } from "@context/LocalContext";
import {
  Text,
  Input,
  Button,
  Select,
  Card,
  useBodyScroll,
} from "@geist-ui/react";
import colors from "@res/colors";
import fonts from "@res/fonts";
import Success from "@components/SuccessComponent/SuccessComponent";
import { motion } from "framer-motion";
import { Ionicons } from "@expo/vector-icons";
import { InView } from "react-intersection-observer";
import { toast } from "react-toastify";
import _ from "lodash";
import pickTextColor from "../../../../functions/pickTextColor";

const EntityInput = ({ order, onExecute, isExecuting }) => {
  const {
    selectTab,
    state: { gradients, tabs, plan },
  } = useContext(PlanContext);
  const {
    modDatabase,
    state: { localDatabase },
  } = useContext(LocalContext);
  const [amountToSend, setAmountToSend] = useState(10);
  const actionColor = pickTextColor(
    plan.company.darkColor,
    plan.company.darkColor,
    plan.company.lightColor
  );

  const stepContent = tabs[order];

  const onSelect = (entity) => {
    modDatabase({ key: stepContent.dbTitle, value: entity });
  };

  return (
    <View style={{ width: "100%", alignSelf: "center", paddingHorizontal: 10 }}>
      <Card>
        <View>
          <View
            style={{
              width: "100%",
              padding: 25,
            }}
          >
            <Text h3>{eval(stepContent.content.header)}</Text>

            <View style={{ marginVertical: 5 }} />

            <View
              style={{
                backgroundColor: "#f5fffb",
                padding: 15,
                borderRadius: 5,
                borderColor: plan.company.headingColor,
                borderWidth: 2,
                flexDirection: "row",
              }}
            >
              <Image
                source={{ uri: plan.prospectIcon }}
                resizeMode="contain"
                style={{
                  height: 50,
                  width: 50,
                  borderRadius: 50,
                  backgroundColor: "white",
                  marginRight: 15,
                }}
              />
              <View>
                <Text small font="11px">
                  ORIGINATING ACCOUNT
                </Text>
                <Text style={{ marginTop: 0 }}>
                  {plan.prospectCompany} Fund Account
                </Text>
                <Text small style={{ color: "grey" }} font="11px">
                  0f8e3719-3dfd-4613-9bbf-c0333781b59f
                </Text>
              </View>
            </View>

            <View
              style={{
                alignSelf: "center",
                width: 1,
                height: 25,
                borderLeftColor: "grey",
                borderLeftWidth: 1,
                borderStyle: "dashed",
              }}
            />

            <View
              style={{
                alignSelf: "center",
                width: "40%",
              }}
            >
              <Input label="Amount $" placeholder="10.00" htmlType="number" />
            </View>

            <View
              style={{
                alignSelf: "center",
                width: 1,
                height: 25,
                borderLeftColor: "grey",
                borderLeftWidth: 1,
                borderStyle: "dashed",
              }}
            />

            {localDatabase.counterparty != null ? (
              <View
                style={{
                  backgroundColor: "#f5fffb",
                  padding: 15,
                  borderRadius: 5,
                  borderColor: plan.company.headingColor,
                  borderWidth: 2,
                  flexDirection: "row",
                }}
              >
                <Image
                  source={{ uri: localDatabase.counterparty.picture }}
                  resizeMode="cover"
                  style={{
                    height: 50,
                    width: 50,
                    borderRadius: 50,
                    backgroundColor: "white",
                    marginRight: 15,
                  }}
                />
                <View>
                  <Text small font="11px">
                    RECEIVING ACCOUNT
                  </Text>
                  <Text style={{ marginTop: 0 }}>
                    {localDatabase.counterparty.name} Fund Account
                  </Text>
                  <Text small style={{ color: "grey" }} font="11px">
                    Account Number: {localDatabase.counterparty.account_number}
                  </Text>
                </View>
              </View>
            ) : (
              <Text style={{ marginTop: 10, textAlign: "center" }} small>
                No recipient found
              </Text>
            )}

            <View style={{ marginVertical: 20 }} />

            {localDatabase.counterparty == null ? (
              <Button width={"100%"} style={{ fontWeight: "bold" }} disabled>
                No Counterparty Created
              </Button>
            ) : (
              <Button
                width={"100%"}
                type="secondary-light"
                onClick={() => {
                  onExecute();
                }}
              >
                {eval(stepContent.content.buttonLabel)}
              </Button>
            )}
          </View>
        </View>
      </Card>
    </View>
  );
};

const styles = StyleSheet.create({
  botContainer: {
    alignSelf: "center",
    width: "80%",
    alignItems: "center",
    justifyContent: "center",
    maxWidth: 600,
    marginTop: 20,
    marginBottom: 20,
    borderRadius: 5,
    padding: 35,
  },
  botBackground: {
    position: "absolute",
    borderRadius: 5,
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    height: "100%",
    zIndex: -1,
  },
  dashedLine: {
    alignSelf: "center",
    borderLeftWidth: 1,
    borderStyle: "dashed",
    height: 100,
    width: 0,
  },
});

export default EntityInput;
