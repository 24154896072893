import createDataContext from "./createDataContext";
import serverApi from "../api/server";
import * as RootNavigation from "../RootNavigation";
import * as Sentry from "sentry-expo";
import _ from "lodash";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { toast } from "react-toastify";
import generateGradient from "../functions/generateGradient";

const onErr = async (err, route) => {
  console.log(`${route} error`);
  console.log(err);
  const userIdentification = await AsyncStorage.getItem("userId");
  Sentry.Browser.configureScope(function (scope) {
    scope.setExtra("function_name", route);
    scope.setExtra("id", userIdentification);
    Sentry.Browser.captureException(err);
  });
  toast.error("Having trouble connecting to the server... Try again in a few.");
};

const calcTabs = (content) => {
  const tabs = [];

  if (content.letter != null && content.letter != "") {
    tabs.push({
      order: 0,
      type: 0,
      title: "Overview",
    });
  }

  for (let i = 0; i < content.length; i++) {
    tabs.push({
      order: tabs.length,
      type: 1, // GOAL
      title: content[i].goalTitle,
      content: content[i].goal,
    });

    const goalOrder = tabs.length - 1;
    let stepOrder = 0;

    const stepArr = content[i].steps;
    for (let ii = 0; ii < stepArr.length; ii++) {
      tabs.push({
        order: tabs.length,
        type: 2, // STEP
        goalOrder: goalOrder,
        stepNumber: stepOrder,
        title: stepArr[ii].stepTitle,
        description: stepArr[ii].stepDescription,
        content: stepArr[ii].step,
      });
      stepOrder++;
    }
  }

  tabs.push({
    order: tabs.length,
    type: 3, // NEXT STEPS
    title: "Next Steps",
    content: content.nextSteps,
  });

  return tabs;
};

const genGradients = (number) => {
  let gradients = [];
  for (let i = 0; i < number; i++) {
    gradients.push(generateGradient());
  }
  return gradients;
};

const planReducer = (state, action) => {
  switch (action.type) {
    case "fetch_plan":
      return {
        ...state,
        plan: action.payload,
        tabs: calcTabs(action.payload.content),
        gradients: genGradients(calcTabs(action.payload.content).length),
      };
    case "select_tab":
      return {
        ...state,
        selectedTab: action.payload,
      };
    case "reset":
      return {
        plan: null,
        selectedTab: 0,
      };
    default:
      return state;
  }
};

const fetchPlan = (dispatch) => async (planId) => {
  try {
    const response = await serverApi.post("/plan", { planId: planId });
    dispatch({ type: "fetch_plan", payload: response.data });
  } catch (err) {
    onErr(err, "fetchPlan");
  }
};

const selectTab = (dispatch) => async (tab) => {
  dispatch({ type: "select_tab", payload: tab });
};

const resetPlanContext = (dispatch) => async () => {
  dispatch({ type: "reset" });
};

export const { Provider, Context } = createDataContext(
  planReducer,
  {
    fetchPlan,
    selectTab,
    resetPlanContext,
  },
  {
    plan: {
      company: {
        _id: "example-ribbon-id",
        name: "Ribbon",
        logo: "https://i.postimg.cc/jSzJR4QN/logo-white.png",
        email: "SUPPORT@RIBBONHEALTH.COM",
        lightColor: "#ceeae1",
        darkColor: "#5f3f6e",
        headingColor: "#322139",
        firstGradientColor: "#5f3f6e",
        secondGradientColor: "#6d2a8c",
        fontImport: "Space Grotesk:300,400,800",
        bodyFont: "Space Grotesk",
        headingFont: "Space Grotesk:300",
      },
      prospectCompany: "Acme, Inc.",
      prospectName: "John",
      apiKey: "sk_SRedsgh4364gj773jLk3c434",
      letter:
        "We’re excited to show you how Ribbon can support Acme, Inc.'s provider search needs.\nThis is a customized link containing your integration plan in order to power a comprehensive provider search for your users.\nOur goal is to help you visualize how Ribbon fits with your use case.\n- The Ribbon Team",
      // prospectLogo:
      //   "https://www.anthemis.com/wp-content/uploads/2020/07/pipe-logo.png",
      // prospectIcon:
      //   "https://res.cloudinary.com/crunchbase-production/image/upload/c_lpad,f_auto,q_auto:eco,dpr_1/ghocsipwbmjw2vvwe7ju",

      content: [
        {
          goalTitle: "Comprehensive Provider Search",
        },
      ],
    },
    tabs: [
      {
        order: 0,
        type: 0,
        title: "Overview",
      },
      {
        order: 1,
        type: 1,
        title: "Comprehensive Provider Search",
        description: `With Ribbon, you can power a comprehensive provider search that allows users to find specific specialists in their neighborhood who accept their insurance`,
      },
      {
        order: 2,
        type: 2,
        goalOrder: 1,
        stepNumber: 1,
        title: "Location",
        dbTitle: "location",
        description: `The first parameter for your provider search will be the location. This is a string input of an address that will be interpreted and geocoded in real time.`,
        content: {
          type: 10,
          icon: "ios-location-sharp",
          actionHeading: "Select a location",
          actionSubheading:
            "We interpret and geocode this location in real time",
          objectName: "Location",
          logoUrl:
            "https://assets.website-files.com/5ee9ebae123fc9271144103a/61c370249829031be54e7d67_logo%20a%201color.png",
          data: [],
        },
      },
      {
        order: 3,
        type: 2,
        goalOrder: 1,
        stepNumber: 2,
        title: "Insurance Plan",
        dbTitle: "carrier",
        description: `String input of carrier_name in order to search for all providers that take at least one plan from a given insurance carrier.`,
        content: {
          type: 12,
          icon: "card-sharp",
          actionHeading: "Select an Insurance Carrier",
          actionSubheading:
            "We'll search for all providers that accept at least one plan from this carrier.",
          searchRequest: `curl --location --request GET 'https://test.ribbonhealth.com/v1/custom/insurances?search=<REPLACE>'
            --header 'x-api-key: JQhh72WWtbLtHkqpqhLW6npfPCZshuE9MYB784rc'
            --header 'Content-Type: application/json'`,
          searchDbTitle: "carrierSearch",
          objectName: "Carrier",
          searchString: "carrier or plan",
          stages: ["carrier", "plan"],
          dataKey: `results`,
          idKey: `uuid`,
          stageData: [
            `localDatabase.carrierSearch.results`,
            `localDatabase.carrierSearch.results`,
          ],
          stageKeys: [`carrier_name`, `plan_name`],
          snippetKey: "insurance_ids",
        },
      },
      {
        order: 4,
        type: 2,
        goalOrder: 1,
        stepNumber: 3,
        title: "Clinical Areas",
        dbTitle: "specialty",
        description: `String input of a provider specialty that will be interpreted and matched to the single closest specialty, dealing with basic typos and colloquial names for providers. For example, to find Gastroenterologists you could search any of the following: gastroenterology, gastroenterologist, gastro, stomach doc, gastrooentrology`,
        content: {
          type: 12,
          icon: "bandage-sharp",
          actionHeading: "Clinical Areas",
          actionSubheading:
            "We'll find you the best providers for your user's specific needs",
          objectName: "Treatment",
          searchString: "specialty or treatment",
          logoUrl:
            "https://assets.website-files.com/5ee9ebae123fc9271144103a/61c370249829031be54e7d67_logo%20a%201color.png",
          searchRequest: `curl --location --request GET 'https://test.ribbonhealth.com/v1/custom/clinical_areas?search=<REPLACE>'
            --header 'x-api-key: JQhh72WWtbLtHkqpqhLW6npfPCZshuE9MYB784rc'
            --header 'Content-Type: application/json'`,
          searchDbTitle: "areaSearch",
          dataKey: `results`,
          idKey: `uuid`,
          stages: ["area", ["treatment", "condition"]],
          stageData: [
            `localDatabase.areaSearch.results[0].specialties`,
            `localDatabase.areaSearch.results[0].conditions.map((elem) => { return {...elem, type: "condition"} }).concat(localDatabase.areaSearch.results[0].treatments.map((elem) => { return {...elem, type: "condition"} }))`,
          ],
          stageKeys: [`display`, `display`],
          snippetKey: "specialty_ids_primary",
        },
      },
      {
        order: 5,
        type: 2,
        goalOrder: 1,
        stepNumber: 4,
        title: "Execute Search",
        dbTitle: "search",
        description: `Time to put it all together! Using all of the selections from prior steps, we've created your request for you.`,
        content: {
          type: 0,
          visualType: 0,
          firstParam: "type",
          lastParam: "dob",
          codeSnippet: `curl --location --request GET 'https://test.ribbonhealth.com/v1/custom/providers?address=33 Irving Pl, NYC&specialty_ids=52f8nj62-eb1c-47fa-ab3e-222dfd2fr35&treatment_ids=1de33770-eb1c-47fa-ab3e-f9a4ab924d9d&condition_ids=h4jt6j7h-eb1c-47fa-ab3e-657jg2hf4bf6szk&insurance_ids=2d5231c0-2d29-4026-9138-5dcbd0591404&min_confidence=2'
          --header 'x-api-key: JQhh72WWtbLtHkqpqhLW6npfPCZshuE9MYB784rc'
          --header 'Content-Type: application/json'`,
          requestFields: [
            {
              key: "Location",
              codeKey: "address",
              value: "33 Irving Pl, NYC",
              type: 1,
              desc: "Location",
              preloadKey: "location.formatted_address",
              preloadDesc: "location.name",
            },
            {
              key: "Carrier",
              codeKey: "insurance_ids",
              value: "2d5231c0-2d29-4026-9138-5dcbd0591404",
              type: 1,
              desc: "Carrier",
              preloadKey: "carrier.plan.uuid",
              preloadDesc: "carrier.plan.plan_name",
            },
            {
              key: "Specialties",
              codeKey: "specialty_ids",
              value: "52f8nj62-eb1c-47fa-ab3e-222dfd2fr35",
              type: 1,
              desc: "Clinical Area",
              preloadKey: "specialty.area.uuid",
              preloadDesc: "specialty.area.display",
            },
            {
              key: "Treatment",
              codeKey: "treatment_ids",
              value: "1de33770-eb1c-47fa-ab3e-f9a4ab924d9d",
              type: 1,
              desc: "Treatment",
              preloadKey: "specialty.treatment.uuid",
              preloadDesc: "specialty.treatment.display",
              optional: true,
            },
            {
              key: "Condition",
              codeKey: "condition_ids",
              value: "h4jt6j7h-eb1c-47fa-ab3e-657jg2hf4bf6szk",
              type: 1,
              desc: "Condition",
              preloadKey: "specialty.condition.uuid",
              preloadDesc: "specialty.condition.display",
              optional: true,
            },
            {
              key: "Confidence",
              codeKey: "min_confidence",
              codeValue: 2,
              value: "20%",
              type: 3,
              desc: "Minimum Confidence",
            },
          ],
          botDetails: [
            {
              title: "Connecting to Provider Database",
              sub: [
                {
                  key: "Status",
                  value: "Completed",
                },
              ],
            },
            {
              title: "Filtering Location and Insurance",
              sub: [
                {
                  key: "Status",
                  value: "Completed",
                },
              ],
            },
            {
              title: "Retrieving Provider Data",
              sub: [
                {
                  key: "Status",
                  value: "Completed",
                },
              ],
            },
          ],
          success: {
            order: 3,
            componentType: 6, // DataList
            header: `"Total Providers: " + localDatabase.search.parameters.total_count`,
            subHeader: `"Provider List Fetched"`,
            label: `localDatabase.search.parameters.address.trim(0, 15) + "..."`,
            successLabel: `"Search Executed"`,
            data: `search.data`,
            preFillData: [],
          },
        },
      },

      {
        order: 6,
        type: 4,
        title: "All Done!",
        description:
          "Ribbon Health provides healthcare enterprises with an API layer for accurate data on doctors, insurance plans, and costs & quality of care.",
      },
      {
        order: 8,
        type: 3,
        title: "Next Steps",
        description: `To move forward, let's schedule a 1-on-1 consultation. We'll answer any question you may have and figure out the best plan to proceed and support you.`,
      },
    ], // FIXME: remove and set to null for prod
    selectedTab: {
      index: 0,
      type: "auto",
    },
    gradients: genGradients(7),
  }
);
