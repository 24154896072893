import React, { useContext, useState, useEffect, useMemo } from "react";
import {
  StyleSheet,
  View,
  Image,
  ScrollView,
  TouchableOpacity,
  ImageBackground,
} from "react-native";
import { Context as PlanContext } from "@context/PlanContext";
import { Context as LocalContext } from "@context/LocalContext";
import { Text, Input, Button, Card, Progress } from "@geist-ui/react";
import colors from "@res/colors";
import fonts from "@res/fonts";
import { Ionicons } from "@expo/vector-icons";
import { motion } from "framer-motion";
import { toast } from "react-toastify";
import { useDropzone } from "react-dropzone";
import "../Connect/devices.min.css";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const focusedStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

const PhoneAction = ({ order, stepContent }) => {
  const {
    state: { plan },
  } = useContext(PlanContext);
  const {
    modDatabase,
    state: { localDatabase },
  } = useContext(LocalContext);
  const [stage, setStage] = useState(0);
  const [loadedFile, setLoadedFile] = useState(null);
  const [loadReceipt, setLoadReceipt] = useState(-1);

  const {
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: "image/jpeg,image/png",
  });

  useEffect(() => {
    if (acceptedFiles.length > 0) {
      setLoadedFile(acceptedFiles[acceptedFiles.length - 1]);
      console.log(acceptedFiles[acceptedFiles.length - 1]);
    }
  }, [acceptedFiles, fileRejections]);

  const onSelect = (receipt) => {
    modDatabase({ key: stepContent.dbTitle, value: receipt });
  };

  const dropStyle = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  const selectView = () => (
    <View
      style={{
        height: "100%",
        width: "100%",
        alignSelf: "center",
        paddingVertical: 50,
        paddingHorizontal: 30,
        justifyContent: "space-around",
      }}
    >
      <motion.div
        style={{ border: "1px solid #e3e3e3", borderRadius: 5 }}
        whileHover={{ borderColor: plan.company.lightColor, scale: 1.05 }}
      >
        <TouchableOpacity onPress={() => setStage(1)}>
          <View style={{ padding: 20 }}>
            <Image
              source={require("../../../../assets/images/file-text-dynamic-premium.png")}
              style={{
                height: 125,
                width: 125,
                alignSelf: "center",
              }}
            />
            <View style={{ marginVertical: 10 }} />
            <Text h4>Upload a Receipt</Text>
            <Text style={{ color: "grey" }} small>
              Select your own receipt to scan in real-time
            </Text>
          </View>
        </TouchableOpacity>
      </motion.div>
      <motion.div
        style={{ border: "1px solid #e3e3e3", borderRadius: 5 }}
        whileHover={{
          borderColor: plan.company.headingColor,
          scale: 1.05,
        }}
      >
        <TouchableOpacity onPress={() => setStage(2)}>
          <View style={{ padding: 20 }}>
            <Image
              source={require("../../../../assets/images/notebook-dynamic-premium.png")}
              style={{
                height: 125,
                width: 125,
                alignSelf: "center",
              }}
            />
            <View style={{ marginVertical: 10 }} />
            <Text h4>Select a Receipt</Text>
            <Text style={{ color: "grey" }} small>
              Use one of the receipts we already have loaded
            </Text>
          </View>
        </TouchableOpacity>
      </motion.div>
    </View>
  );

  const uploadView = () => (
    <View
      style={{
        height: "100%",
        width: "100%",
        paddingVertical: 50,
        paddingHorizontal: 30,
        justifyContent: "space-between",
      }}
    >
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <TouchableOpacity onPress={() => setStage(0)}>
          <Ionicons name="arrow-back" size={24} color="black" />
        </TouchableOpacity>
        <Text h4>Upload a Receipt</Text>
        <View />
      </View>

      <View>
        <div {...getRootProps({ style: dropStyle })}>
          <input {...getInputProps()} />
          <View>
            <Ionicons
              name="arrow-down-circle-outline"
              size={80}
              color="#bfbfbf"
            />
          </View>
          <p>Drag 'n' drop some files here, or click to select files</p>
          <em>(Only *.jpeg and *.png images will be accepted)</em>
          {loadedFile != null ? (
            <Text style={{ color: "black" }}>
              {loadedFile.path} - {loadedFile.size}
            </Text>
          ) : null}
        </div>
      </View>

      {loadedFile == null ? (
        <Button width={"100%"} style={{ fontWeight: "bold" }} disabled>
          No receipt uploaded
        </Button>
      ) : (
        <Button
          width={"100%"}
          style={{
            padding: 0,
            backgroundColor: plan.company.darkColor,
            borderColor: plan.company.darkColor,
          }}
          type="secondary-light"
          onClick={() => {
            setStage(3);
          }}
        >
          <Text style={{ color: plan.company.lightColor }} b>
            {loadedFile.path.length > 15
              ? `${loadedFile.path.substring(0, 15)}...`
              : loadedFile.path}{" "}
            Uploaded
          </Text>
        </Button>
      )}
    </View>
  );

  const loadView = () => (
    <View
      style={{
        height: "100%",
        width: "100%",
        paddingVertical: 50,
        paddingHorizontal: 30,
      }}
    >
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: 25,
        }}
      >
        <TouchableOpacity onPress={() => setStage(0)}>
          <Ionicons name="arrow-back" size={24} color="black" />
        </TouchableOpacity>
        <Text h4>Select a Receipt</Text>
        <View />
      </View>

      {[
        {
          category: "Home Office",
          description:
            "These include costs like rent, paint, repairs, utilities, and mortgage interest.",
        },
        {
          category: "Travel",
          description:
            "Business travel constitutes travel outside city limits, including vehicle costs, airfare, accommodations, and meals.",
        },
        {
          category: "Meals",
          description:
            "Meals that are for company clients, as well as lunches for employees qualify as meal expenses.",
        },
        {
          category: "Utilities",
          description:
            "Include the monthly costs of electricity, gas, water, air conditioning, and trash.",
        },
      ].map((elem, index) => {
        return (
          <View style={{ marginVertical: 8 }} key={`${elem.category}`}>
            <motion.div
              style={{
                border: "1px solid #f5f5f5",
                borderRadius: 5,
              }}
              whileHover={{ borderColor: plan.company.lightColor, scale: 1.05 }}
            >
              <TouchableOpacity
                style={{
                  backgroundColor: "#f5f5f5",
                  width: "100%",
                  padding: 15,
                  flexDirection: "row",
                  borderColor:
                    loadReceipt == index ? plan.company.lightColor : "#f5f5f5",
                  borderWidth: 1,
                  borderRadius: 5,
                }}
                onPress={() => {
                  setLoadReceipt(index);
                  onSelect(elem);
                }}
              >
                <Ionicons
                  name="document-text"
                  size={24}
                  color={plan.company.headingColor}
                />
                <View style={{ marginHorizontal: 10 }} />
                <View style={{ width: "75%" }}>
                  <Text style={{ marginBottom: 0 }} h5>
                    {elem.category} Receipt
                  </Text>
                  <Text small style={{ color: "grey" }} font="12px">
                    {elem.description.substring(0, 50)}...
                  </Text>
                </View>
              </TouchableOpacity>
            </motion.div>
          </View>
        );
      })}
    </View>
  );

  const phoneElem = (content) => {
    return (
      <div className="marvel-device iphone-x" style={{ "--size-divisor": 1.2 }}>
        <div className="notch">
          <div className="camera"></div>
          <div className="speaker"></div>
        </div>
        <div className="top-bar"></div>
        <div className="sleep"></div>
        <div className="bottom-bar"></div>
        <div className="volume"></div>
        <div className="overflow">
          <div className="shadow shadow--tr"></div>
          <div className="shadow shadow--tl"></div>
          <div className="shadow shadow--br"></div>
          <div className="shadow shadow--bl"></div>
        </div>
        <div className="inner-shadow"></div>
        <div className="screen">{content}</div>
      </div>
    );
  };

  return (
    <View
      style={{
        width: "100",
        alignContent: "center",
        justifyContent: "center",
      }}
    >
      <View style={{ alignSelf: "center" }}>
        {phoneElem(
          <>
            {stage == 0 ? selectView() : null}
            {stage == 1 ? uploadView() : null}
            {stage == 2 ? loadView() : null}
          </>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  databaseView: {
    padding: 30,
    alignSelf: "center",
    borderRadius: 10,
    backgroundColor: "white",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.1,
    shadowRadius: 15,

    elevation: 9,
  },
});

export default PhoneAction;
