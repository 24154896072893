import React, { useContext, useState, useEffect, useRef } from "react";
import {
  StyleSheet,
  View,
  Image,
  ScrollView,
  useWindowDimensions,
  ActivityIndicator,
  TouchableOpacity,
} from "react-native";
import { Context as PlanContext } from "@context/PlanContext";
import { Context as LocalContext } from "@context/LocalContext";
import { useMediaQuery } from "react-responsive";
import { Text, Button, Tag, Card, Input, Dot } from "@geist-ui/react";
import colors from "@res/colors";
import fonts from "@res/fonts";
import { Ionicons } from "@expo/vector-icons";
import { motion } from "framer-motion";
import JsxParser from "react-jsx-parser";
import pickTextColor from "../../functions/pickTextColor";

const Entity = ({ content }) => {
  const {
    state: { plan, gradients },
  } = useContext(PlanContext);
  const {
    state: { localDatabase },
  } = useContext(LocalContext);
  const actionColor = pickTextColor(
    plan.company.darkColor,
    plan.company.darkColor,
    plan.company.lightColor
  );

  const dataViewOne = () => (
    <Card style={{ width: "100%", alignSelf: "center", marginBottom: 25 }}>
      <View
        style={{
          width: "100%",
          justifyContent: "space-between",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <View>
          {content.data.map((elem, index) => (
            <View
              key={elem.key}
              style={{
                flexDirection: "row",
                alignItems: "center",
                marginBottom: index == [1, 2, 3, 4].length ? 0 : 15,
              }}
            >
              <Ionicons
                name={elem.icon}
                size={14}
                color={plan.company.darkColor}
                style={{ marginRight: 6 }}
              />
              <Text style={{ color: plan.company.darkColor }} small>
                {elem.key}
              </Text>
            </View>
          ))}
        </View>
        <View>
          {content.data.map((elem, index) => (
            <View
              key={elem.value}
              style={{
                marginBottom: index == [1, 2, 3, 4].length ? 0 : 15,
              }}
            >
              <Text style={{ textAlign: "end" }} small>
                {eval(elem.value)}
              </Text>
            </View>
          ))}
        </View>
      </View>
    </Card>
  );

  const dataViewTwo = () => (
    <View>
      {content.data.map((elem, index) => (
        <View
          key={elem.key}
          style={{
            // background:
            //   "linear-gradient(" +
            //   120 +
            //   "deg, " +
            //   "black" +
            //   ", " +
            //   "#262626" +
            //   ")",
            background: gradients[content.order],
            width: "100%",

            padding: 30,
            borderRadius: 5,
            alignItems: "flex-end",
            marginVertical: 10,
          }}
        >
          <Text
            style={{ color: "white", textAlign: "end", marginBottom: -30 }}
            b
          >
            {elem.key.toUpperCase()}
          </Text>
          <View style={{ marginVertical: 15 }} />
          <Text
            style={{ color: "white", textAlign: "end", marginBottom: -10 }}
            h2
          >
            {eval(elem.value)}
          </Text>
        </View>
      ))}
      <View style={{ marginBottom: 40 }} />
    </View>
  );

  return (
    <View
      style={[
        styles.databaseView,
        { borderTopColor: actionColor, borderTopWidth: 3 },
      ]}
    >
      <View style={{ width: 300, justifyContent: "space-between" }}>
        <View
          style={[
            styles.labelContainer,
            {
              backgroundColor: pickTextColor(
                plan.company.darkColor,
                plan.company.lightColor,
                plan.company.darkColor
              ),
            },
          ]}
        >
          <View style={[styles.labelDot, { backgroundColor: actionColor }]} />
          <Text style={{ color: actionColor }} small b font="10px">
            Active
          </Text>
        </View>
        <View
          style={{
            alignSelf: "center",
            alignItems: "center",
            marginBottom: 25,
          }}
        >
          <View
            style={{
              height: 50,
              width: 50,
              borderRadius: 50,
              backgroundColor: plan.company.darkColor,
              marginBottom: 15,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Ionicons
              name={content.headerIcon}
              size={24}
              color={plan.company.lightColor}
            />
          </View>
          <Text
            style={{
              marginBottom: -10,
              textAlign: "center",
              color: actionColor,
              fontFamily:
                plan.company.headingFont != null
                  ? plan.company.headingFont
                  : null,
            }}
            h4
          >
            {eval(content.header)}
          </Text>
          <Text style={{ color: "grey", textAlign: "center" }}>
            {eval(content.subHeader)}
          </Text>
        </View>

        {content.visualView == null || content.visualView == 0
          ? dataViewOne()
          : null}
        {content.visualView == 1 ? dataViewTwo() : null}

        <TouchableOpacity
          style={{
            backgroundColor: plan.company.darkColor,
            width: "100%",
            paddingVertical: 8,
            alignItems: "center",
            justifyContent: "space-between",
            borderRadius: 5,
            flexDirection: "row",
            paddingHorizontal: 15,
          }}
        >
          <Ionicons
            name="ios-checkmark-circle"
            size={24}
            color={plan.company.lightColor}
          />
          <Text style={{ color: plan.company.lightColor }} small b>
            {eval(content.successLabel)}
          </Text>
          <View />
        </TouchableOpacity>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  databaseView: {
    padding: 30,
    alignSelf: "center",
    borderRadius: 10,
    backgroundColor: "white",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.1,
    shadowRadius: 15,
    elevation: 9,
  },
  labelContainer: {
    backgroundColor: "#DEFFF6",
    paddingVertical: 8,
    paddingHorizontal: 15,
    borderRadius: 50,
    position: "absolute",
    top: 0,
    left: 0,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  labelDot: {
    height: 8,
    width: 8,
    borderRadius: 50,

    marginRight: 6,
  },
});

export default Entity;
