import React, { useContext, useState, useEffect, useRef } from "react";
import {
  StyleSheet,
  View,
  ScrollView,
  useWindowDimensions,
  ActivityIndicator,
  TouchableOpacity,
  Image,
} from "react-native";
import { Context as PlanContext } from "@context/PlanContext";
import { useMediaQuery } from "react-responsive";
import { Text, Button, Tag, Card, Input, Table } from "@geist-ui/react";
import colors from "@res/colors";
import fonts from "@res/fonts";
import { Ionicons } from "@expo/vector-icons";
import { motion } from "framer-motion";
import JsxParser from "react-jsx-parser";
import RemoteImage from "../../../../../components/RemoteImage";
import Success from "@components/SuccessComponent/SuccessComponent";
import pickTextColor from "../../../../../functions/pickTextColor";

const NextSteps = ({ order }) => {
  const {
    state: { plan, tabs },
  } = useContext(PlanContext);
  const scrollRef = useRef(null);
  const [animating, setAnimating] = useState(false);

  const stepContent = tabs[order];

  return (
    <View style={styles.container}>
      <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
        <View style={{ width: "100%" }}>
          <Text
            style={{
              color: plan.company.headingColor,
              marginBottom: -10,
              fontFamily:
                plan.company.headingFont != null
                  ? plan.company.headingFont
                  : null,
            }}
            h1
          >
            Mission
          </Text>
          <Text
            style={{
              color: pickTextColor(
                plan.company.darkColor,
                plan.company.darkColor,
                plan.company.lightColor
              ),
              marginBottom: -10,
              fontFamily:
                plan.company.headingFont != null
                  ? plan.company.headingFont
                  : null,
            }}
            h1
          >
            {stepContent.title}
          </Text>
          <View style={{ marginVertical: 15 }} />
          <Text
            style={{
              color: pickTextColor(
                plan.company.darkColor,
                plan.company.darkColor,
                "#292929"
              ),
            }}
            font="16px"
          >
            {stepContent.description}
          </Text>
          <View style={{ marginVertical: 10 }} />
          <View style={{ alignSelf: "flex-start" }}>
            <Button
              style={{ fontWeight: "bold" }}
              type="secondary"
              onClick={() => {
                console.log("Book a call");
              }}
            >
              Book a Call
            </Button>
          </View>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: "100%",
    paddingBottom: 100,
  },
});

export default NextSteps;
