import React from "react";
import { StyleSheet, View, ScrollView, ActivityIndicator } from "react-native";
import CodeFields from "./CodeFields";
import EntitySelect from "./VisualComps/EntitySelect";
import EntityInput from "./VisualComps/EntityInput";

const VisualComponent = ({
  type,
  order,
  onExecute,
  isExecuting,
  curlRequest,
  requestFields,
  changedVars,
  executeTrigger,
}) => {
  switch (type) {
    case 0:
      return (
        <CodeFields
          order={order}
          onExecute={onExecute}
          isExecuting={isExecuting}
          curlRequest={curlRequest}
          requestFields={requestFields}
          changedVars={changedVars}
          executeTrigger={executeTrigger}
        />
      );
    case 1:
      return (
        <EntitySelect
          order={order}
          onExecute={onExecute}
          isExecuting={isExecuting}
          executeTrigger={executeTrigger}
        />
      );
    case 2:
      return (
        <EntityInput
          order={order}
          onExecute={onExecute}
          isExecuting={isExecuting}
          executeTrigger={executeTrigger}
        />
      );
  }
};

const styles = StyleSheet.create({});

export default VisualComponent;
