import React, { useContext, useState, useEffect, useRef } from "react";
import {
  StyleSheet,
  View,
  useWindowDimensions,
  ActivityIndicator,
  ScrollView,
  TouchableOpacity,
  Image,
} from "react-native";
import { Context as PlanContext } from "@context/PlanContext";
import { Context as LocalContext } from "@context/LocalContext";
import {
  Text,
  Input,
  Button,
  Select,
  Card,
  useBodyScroll,
} from "@geist-ui/react";
import colors from "@res/colors";
import fonts from "@res/fonts";
import Success from "@components/SuccessComponent/SuccessComponent";
import { motion } from "framer-motion";
import { Ionicons } from "@expo/vector-icons";
import { InView } from "react-intersection-observer";
import { toast } from "react-toastify";
import _ from "lodash";
import pickTextColor from "../../../../functions/pickTextColor";

const EntitySelect = ({ order, onExecute, isExecuting }) => {
  const {
    selectTab,
    state: { gradients, tabs, plan },
  } = useContext(PlanContext);
  const {
    modDatabase,
    state: { localDatabase },
  } = useContext(LocalContext);
  const actionColor = pickTextColor(
    plan.company.darkColor,
    plan.company.darkColor,
    plan.company.lightColor
  );

  const stepContent = tabs[order];

  const onSelect = (entity) => {
    modDatabase({ key: stepContent.dbTitle, value: entity });
  };

  return (
    <View style={{ width: "100%", alignSelf: "center", paddingHorizontal: 10 }}>
      <Card>
        <View>
          <View
            style={{
              alignItems: "center",
              width: "100%",
              padding: 25,
            }}
          >
            <View
              style={{
                alignSelf: "flex-start",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Image
                source={{ uri: eval(stepContent.content.headerLogo) }}
                style={{
                  height: 50,
                  width: 50,
                  borderRadius: 50,
                  borderWidth: 1,
                  borderColor: "lightgrey",
                  marginRight: 20,
                }}
                resizeMode="contain"
              />
              <Text h3>{eval(stepContent.content.header)}</Text>
            </View>
            <View style={{ marginVertical: 20 }} />

            <View
              style={{
                borderColor: "lightgrey",
                borderWidth: 0.5,
                borderRadius: 5,
              }}
            >
              <ScrollView
                showsVerticalScrollIndicator={false}
                contentContainerStyle={{
                  maxHeight: 325,
                  width: "100%",
                  justifyContent: "space-between",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  alignItems: "center",
                  paddingHorizontal: 8,
                }}
              >
                {stepContent.content.entities.map((elem) => {
                  return (
                    <motion.div
                      key={elem.name}
                      style={{ width: "48%" }}
                      whileHover={{ scale: 1.1 }}
                      layout
                    >
                      <TouchableOpacity
                        style={{
                          width: "100%",
                          marginVertical: 8,
                          padding: 15,
                          borderRadius: 4,
                          backgroundColor: "#F5F5F5",
                          // borderColor: "lightgrey",
                          // borderWidth: 2,
                        }}
                        onPress={() => onSelect(elem)}
                      >
                        <Image
                          source={{
                            uri: elem.picture,
                          }}
                          resizeMode="cover"
                          style={{
                            height: 75,
                            width: 75,
                            borderRadius: 75,
                            backgroundColor: "white",
                            marginBottom: 5,
                            alignItems: "center",
                            justifyContent: "center",
                            borderColor: "lightgrey",
                            borderWidth: 1,
                            alignSelf: "center",
                          }}
                        />
                        <Text style={{ paddingBottom: 10, paddingTop: 5 }} b>
                          {elem.name}
                        </Text>
                        <Text
                          style={{ marginTop: 0, marginBottom: 2 }}
                          font="12px"
                        >
                          <Text span b>
                            Routing Type:
                          </Text>{" "}
                          {elem.routing_number_type}
                        </Text>

                        <Text
                          style={{ marginTop: 0, marginBottom: 2 }}
                          font="12px"
                        >
                          <Text span b>
                            Routing No:
                          </Text>{" "}
                          {elem.routing_number}
                        </Text>
                        <Text
                          style={{ marginTop: 0, marginBottom: 2 }}
                          font="12px"
                        >
                          <Text span b>
                            Acct Type:
                          </Text>{" "}
                          {elem.account_type}
                        </Text>

                        <Text
                          style={{ marginTop: 0, marginBottom: 2 }}
                          font="12px"
                        >
                          <Text span b>
                            Acct No:
                          </Text>{" "}
                          {elem.account_number}
                        </Text>
                      </TouchableOpacity>
                    </motion.div>
                  );
                })}
              </ScrollView>
            </View>

            <View style={{ marginVertical: 20 }} />
            {!localDatabase[stepContent.dbTitle] ||
            localDatabase[stepContent.dbTitle] == null ? (
              <Button width={"100%"} style={{ fontWeight: "bold" }} disabled>
                Select a {eval(stepContent.content.objectTitle)}
              </Button>
            ) : (
              <Button
                width={"100%"}
                type="secondary-light"
                onClick={() => {
                  onExecute({ replace: false });
                }}
              >
                {eval(stepContent.content.buttonLabel)}{" "}
                {eval(stepContent.content.selectedEntity)}
              </Button>
            )}
          </View>
        </View>
      </Card>
    </View>
  );
};

const styles = StyleSheet.create({
  botContainer: {
    alignSelf: "center",
    width: "80%",
    alignItems: "center",
    justifyContent: "center",
    maxWidth: 600,
    marginTop: 20,
    marginBottom: 20,
    borderRadius: 5,
    padding: 35,
  },
  botBackground: {
    position: "absolute",
    borderRadius: 5,
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    height: "100%",
    zIndex: -1,
  },
  dashedLine: {
    alignSelf: "center",
    borderLeftWidth: 1,
    borderStyle: "dashed",
    height: 100,
    width: 0,
  },
});

export default EntitySelect;
