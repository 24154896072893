import React from "react";
import { StyleSheet } from "react-native";
import Endpoint from "./Endpoint/Endpoint";
import Select from "./Select/Select";
import Connect from "./Connect/Connect";
import VisualTrigger from "./VisualTrigger/VisualTrigger";
import ScreenshotOverlay from "./ScreenshotOverlay/ScreenshotOverlay";
import PhoneAction from "./PhoneAction/PhoneAction";
import PhoneTrigger from "./VisualTrigger/PhoneTrigger";
import ChatForm from "./ChatForm/ChatForm";
import CustomOnboarding from "./Connect/CustomOnboarding";
import InputSelect from "./Select/InputSelect";
import Map from "./Map/Map";
import MultiSelect from "./Select/MultiSelect";
import MultiSelectInput from "./Select/MultiSelectInput";

const StepComponent = ({
  order,
  stepContent,
  scrollRef,
  scrollDown,
  isScrolling,
}) => {
  switch (stepContent.content.type) {
    case 0:
      return (
        <Endpoint
          order={order}
          stepContent={stepContent}
          scrollRef={scrollRef}
          scrollDown={scrollDown}
          isScrolling={isScrolling}
        />
      );
    case 1:
      return <Select order={order} stepContent={stepContent} />;
    case 2:
      return <Connect order={order} stepContent={stepContent} />;
    case 3:
      return (
        <VisualTrigger
          order={order}
          stepContent={stepContent}
          scrollRef={scrollRef}
          scrollDown={scrollDown}
          isScrolling={isScrolling}
        />
      );
    case 4:
      return <ScreenshotOverlay order={order} stepContent={stepContent} />;
    case 5:
      return <PhoneAction order={order} stepContent={stepContent} />;
    case 6:
      return (
        <PhoneTrigger
          order={order}
          stepContent={stepContent}
          scrollRef={scrollRef}
          scrollDown={scrollDown}
          isScrolling={isScrolling}
        />
      );
    case 7:
      return <ChatForm order={order} stepContent={stepContent} />;
    case 8:
      return <CustomOnboarding order={order} stepContent={stepContent} />;
    case 9:
      return <InputSelect order={order} stepContent={stepContent} />;
    case 10:
      return <Map order={order} stepContent={stepContent} />;
    case 11:
      return <MultiSelect order={order} stepContent={stepContent} />;
    case 12:
      return <MultiSelectInput order={order} stepContent={stepContent} />;
  }
};

const styles = StyleSheet.create({});

export default StepComponent;
