import React, { useContext, useState, useEffect, useRef } from "react";
import {
  StyleSheet,
  View,
  Image,
  ScrollView,
  TouchableOpacity,
} from "react-native";
import { Context as PlanContext } from "@context/PlanContext";
import { Context as LocalContext } from "@context/LocalContext";
import { Text, Input, Button, Snippet, Slider } from "@geist-ui/react";
import { Ionicons } from "@expo/vector-icons";
import {
  GoogleMap,
  useJsApiLoader,
  Autocomplete,
  OverlayView,
  Circle,
} from "@react-google-maps/api";

console.reportErrorsAsExceptions = false;

const libraries = ["places"];

const Map = ({ order, stepContent }) => {
  const {
    selectTab,
    state: { plan },
  } = useContext(PlanContext);
  const {
    modDatabase,
    state: { localDatabase },
  } = useContext(LocalContext);
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyAtj6HZOzzzIloSoC8FVWqMT72pze9KAPc", // ,
    libraries: libraries,
  });
  const [auto, setAuto] = useState(null);
  const [center, setCenter] = useState({
    lat: 38.685,
    lng: -115.234,
  });
  const [circleOptions, setCircleOptions] = useState({
    strokeColor: "#FF0000",
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: "#FF0000",
    fillOpacity: 0.35,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    radius: 1000,
    zIndex: 1,
  });
  const [radius, setRadius] = useState(1000);

  useEffect(() => {
    setCircleOptions({
      ...circleOptions,
      radius: radius,
    });
  }, [radius]);

  const onSelect = (merchant) => {
    modDatabase({
      key: stepContent.dbTitle,
      value: { ...merchant, snippet: `address=${merchant.formatted_address}` },
    });
  };

  const onLoad = (autocomplete) => {
    console.log("autocomplete: ", autocomplete);

    setAuto(autocomplete);
  };

  const onPlaceChanged = () => {
    if (auto !== null) {
      console.log(auto.getPlace());
      const place = auto.getPlace();
      console.log(place.geometry.location.lat());
      setCenter({
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      });
      onSelect(place);
    } else {
      console.log("Autocomplete is not loaded yet!");
    }
  };

  const renderMap = () => {
    // wrapping to a function is useful in case you want to access `window.google`
    // to eg. setup options or create latLng object, it won't be available otherwise
    // feel free to render directly if you don't need that
    return (
      <GoogleMap
        mapContainerStyle={{
          width: "100%",
          height: 400,
        }}
        center={center}
        zoom={10}
      >
        <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
          <input
            type="text"
            placeholder="Search an address..."
            style={{
              boxSizing: `border-box`,
              border: `1px solid transparent`,
              width: `240px`,
              height: `40px`,
              padding: `0 12px`,
              borderRadius: `3px`,
              boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
              fontSize: `14px`,
              outline: `none`,
              textOverflow: `ellipses`,
              position: "absolute",
              right: 10,
              top: 10,
            }}
          />
        </Autocomplete>

        {localDatabase[stepContent.dbTitle] != null ? (
          <Circle center={center} options={circleOptions} />
        ) : null}
      </GoogleMap>
    );
  };

  return (
    <View style={{ width: "100", minHeight: 500 }}>
      <View
        style={[
          styles.databaseView,
          {
            borderTopColor: plan.company.darkColor,
            borderTopWidth: 3,
            width: "80%",
            maxWidth: 600,
          },
        ]}
      >
        <View style={{ marginBottom: 0 }}>
          <View
            style={{
              flexDirection: "row",
              alignItems: "flex-start",
              marginBottom: -10,
            }}
          >
            <Ionicons
              name={stepContent.content.icon}
              size={25}
              color={plan.company.darkColor}
              style={{ marginRight: 8 }}
            />
            <Text
              style={{
                fontFamily:
                  plan.company.headingFont != null
                    ? plan.company.headingFont
                    : null,
                color: plan.company.darkColor,
              }}
              h3
            >
              {stepContent.content.actionHeading}
            </Text>
          </View>
          <Text style={{ color: plan.company.darkColor }} font="15px">
            {stepContent.content.actionSubheading}
          </Text>
        </View>

        <Text small style={{ marginTop: 0, marginBottom: 15 }}>
          Location Radius:
        </Text>
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <Slider
            initialValue={1000}
            onChange={(val) => {
              setRadius(val);
            }}
            min={5}
            max={9999}
          />
          <View style={{ marginHorizontal: 5 }} />
          <Button
            onClick={() => setRadius(10000)}
            iconRight={
              <View>
                <Text small style={{ marginVertical: 0, paddingVertical: 0 }}>
                  Include All
                </Text>
              </View>
            }
            auto
            scale={2 / 3}
            px={0.6}
          />
        </View>
        <View
          style={{
            borderColor: "lightgrey",
            borderWidth: 0.5,
            borderRadius: 5,
            marginTop: 20,
            marginBottom: 40,
          }}
        >
          {isLoaded ? renderMap() : null}
        </View>
        {localDatabase[stepContent.dbTitle] != null &&
        localDatabase[stepContent.dbTitle].snippet != null ? (
          <Snippet
            symbol=">"
            text={localDatabase[stepContent.dbTitle].snippet}
            type="dark"
            width="100%"
          />
        ) : null}

        <View style={{ marginVertical: 10 }} />
        {!localDatabase[stepContent.dbTitle] ||
        localDatabase[stepContent.dbTitle] == null ? (
          <Button style={{ fontWeight: "bold" }} disabled>
            No {stepContent.content.objectName} Selected
          </Button>
        ) : (
          <Button
            width={"100%"}
            style={{
              padding: 0,
              backgroundColor: plan.company.darkColor,
              borderColor: plan.company.darkColor,
            }}
            type="secondary-light"
            onClick={() => {
              selectTab({ index: order + 1, type: "manual" });
            }}
          >
            <Text style={{ color: plan.company.lightColor }} b>
              {localDatabase[stepContent.dbTitle].name} Selected
            </Text>
          </Button>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  databaseView: {
    padding: 30,
    alignSelf: "center",
    borderRadius: 10,
    backgroundColor: "white",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.1,
    shadowRadius: 15,

    elevation: 9,
  },
});

export default Map;
